
import { System, SystemDetails, SystemPlanStyle } from "@ews/react-data";
import { fontFamilies, fontWeights, labelPosition } from "../../../Plan/components/Element/types";
import { useModifySystemDetails, useSystemDetails } from "../../../ReactData/system";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { PlanViewProps } from "./types";

const PlanView: React.FC<PlanViewProps> = ({ systemId }) =>
{
    const details = useSystemDetails(systemId);
    const modifySystemDetails = useModifySystemDetails();

    const { defaultPlanStyle } = details;

    function saveSystemDetailsDefaultStyle(id: System["id"], data: SystemPlanStyle)
    {

        if (!id || Object.keys(data).length === 0) return;

        const planStyle = { defaultPlanStyle: { ...defaultPlanStyle, ...data } } as SystemDetails;

        modifySystemDetails(id, planStyle);
    }


    const defaultStyle: Required<Omit<SystemPlanStyle, "label">> = {
        labelPosition: "bottom",
        fontWeight: "normal",
        fontFamily: "Times New Roman",
        iconScale: 1,
        fontSize: 1,
    };

    return <>
        <SettingsCol >


            <SettingsSelect
                text="Label Position"
                value={defaultPlanStyle?.labelPosition || defaultStyle.labelPosition}
                onSelect={(labelPosition) =>
                {
                    saveSystemDetailsDefaultStyle(systemId, { labelPosition });
                }}
            >
                {labelPosition.map((position, i) => <SettingsSelectOption key={i} text={position} value={position} />)}

            </SettingsSelect>

            <SettingsSelect
                text="Font Weight"
                value={defaultPlanStyle?.fontWeight || defaultStyle.fontWeight}
                onSelect={(fontWeight) =>
                {
                    saveSystemDetailsDefaultStyle(systemId, { fontWeight });
                }}
            >
                {fontWeights.map((weight, i) => <SettingsSelectOption key={i} text={weight as string} value={weight} />)}

            </SettingsSelect>
            <SettingsSelect
                text="Font Family"
                value={defaultPlanStyle?.fontFamily || defaultStyle.fontFamily}
                onSelect={(fontFamily) =>
                {
                    saveSystemDetailsDefaultStyle(systemId, { fontFamily });
                }}
            >
                {fontFamilies.map((family, i) => <SettingsSelectOption key={i} text={family.label} value={family.label} />)}
            </SettingsSelect>

            <SettingsNumberInput
                lines="none"
                text={"Font Size"}
                debounce={500}
                value={defaultPlanStyle?.fontSize || defaultStyle.fontSize}
                min={1}
                onChange={(fontSize) =>
                {
                    if (fontSize !== 0) {
                        saveSystemDetailsDefaultStyle(systemId, { fontSize });
                    }
                }}
            />
            <SettingsNumberInput
                lines="none"
                text={"Icon Scale"}
                min={1}
                debounce={500}
                value={defaultPlanStyle?.iconScale || defaultStyle.iconScale}
                onChange={(iconScale) =>
                {
                    if (iconScale !== 0) {
                        saveSystemDetailsDefaultStyle(systemId, { iconScale });
                    }
                }}
            />

        </SettingsCol>

        <SettingsCol></SettingsCol>
        <SettingsCol></SettingsCol>
    </>;
};

export default PlanView;