import { de as reactDe } from '@ews/react-translations';

const de = {

    ...reactDe,

    /* navbar */
    "Home": "Home",
    "Systems": "Anlagen",
    "Customers": "Kunden",
    "Users": "Benutzer",

    /* sidebar menu */
    "Menu": "Menü",
    "Select language": "Sprache wählen",
    "My profile": "Mein Profil",
    "Help": "Hilfe",
    "Logout": "Abmelden",

    /* locales */
    "en": "Englisch",
    "de": "Deutsch",

    /* System Settings */

    /* SettingsOverlay */
    "General": "Allgemein",
    "Details": "Details",
    "Rights": "Rechte",
    "License": "Lizenz",
    "Notifications": "Nachrichten",
    "Maintenance reports": "Wartungsbericht",

    /* MainSettings */
    "Aktive": "Aktiv",
    "Assigned client": "Zugeordneter Kunde",
    "Serial number": "Seriennummer",
    "Systems-ID": "Anlagen-ID",
    "System name": "Anlagenname",
    "Alias name": "Aliasname",
    "Location": "Standort",
    "Address": "Anschrift",
    "Postal code": "PLZ",
    "City": "Ort",
    "Alias": "Alias",
    "Street": "Straße",
    "Country": "Land",
    "Created at": "Erstellt am",
    "Updated at": "Geändert am",
    "Send location of system to devices": "Standort der Anlage an die Endgeräte übertragen",

    /* DetailsSettings */
    "Alarm counter according to EN 54-2": "Alarmzähler nach EN 54-2",
    "Current maintenance report since": "Aktueller Wartungsbericht seit",
    "for all elements": "Für alle Elemente",
    "Number of operating elements": "Anzahl der Bedienelemente",
    "Number of actuation elements": "Anzahl der Steuerelemente",
    "Zone configuration file": "Bediengruppen-Konfigurationsdatei",
    "Actuation configuration file": "Steuerungen-Konfigurationsdatei",
    "Last modified": "Zuletzt geändert",
    "Created on": "Angelegt am",
    "Modified by user": "Geändert durch Benutzer",
    "Created by user": "Erstellt durch Benutzer",
    "Created by client": "Erstellt durch Kunden",
    "Time zone": "Zeitzone",
    "Daylight saving time": "Sommerzeit berücksichtigen",

    /* RightsSettings  */
    'Save position data for (min)': 'Positionsdaten speichern für (min)',
    "WLAN check active": "WLAN-Prüfung aktiv",
    "GPS check active": "GPS-Prüfung aktiv",
    "Only one user in authorization": "Nur ein Benutzer in Berechtigung",
    "Operation only possible if FDCP is in authorization level 2/3": "Bedienung nur möglich, wenn die BMZ in Berechtigung 2/3 ist",
    "If session is enabled on the FDCP": "Wenn die Sitzung an der BMZ freigegeben",
    "Show alarming device field in APP": "Feld AE in APP anzeigen",
    "Show transmitting device field in APP": "Feld ÜE in APP anzeigen",
    "Show buzzer field in APP": "Feld Summer in APP anzeigen",
    "Show reset panel field in APP": "Feld BMZ rückstellen in APP anzeigen",
    "WLAN SSID": "WLAN SSID",
    'Admin for rights management': 'Admin für Rechteverwaltung',
    //"Assigned client": "Admin für Rechteverwaltung",
    "E-mail": 'E-mail',
    "Exploration time": "Erkundungszeit",
    "Authorization level": "Berechtigungsstufe",
    "Confirmed": "Bestätigen",


    /* LicenseSettings */
    "Licences": "Lizenzen",
    "Detailed view": "Detailansicht",
    "Operation and push": "Bedienung und Benachrichtigung",
    "Paying client": "Zahlender Kunde",
    "Plan view": "Planansicht",
    "Error": "Fehler",
    "Info": "Info",
    "Warning": "Warnung",
    "Debug": "Debug",
    "Log Level": "Log Level",
    "End of licence": "Ende Lizenz",
    "Notification to": "Benachrichtigung an",
    "Common collective invoice": "Gemeinsame Sammelrechnung",
    "Common collective invoice of licences": "Gemeinsame Sammelrechnung der Lizenzen",


    /* NotificationsSettings */
    "Pre-alarm": "Voralarm",
    "Fire pre-warning": "Brandvorwarnung",
    "Test alarm": "Prüfalarm",
    "Active output": "Aktiver Ausgang",
    "Active Output": "Aktiver Ausgang",
    "Fault": "Störung",
    "Technical message": "Technische Meldung",
    "Disablement": "Abschaltung",
    "Feedback": "Rückmeldung",
    "Extinguishing event": "Löschereignisse",
    "Online status": "Onlinestatus",
    "E-mail addresses for PDF with Fire alarm": "E-Mail-Adressen für PDF mit Brandalarm",
    "receiver1": "Empfänger1",
    "receiver2": "Empfänger2",
    "receiver3": "Empfänger3",

    /* MaintenanceReportsSettings */
    'Time format for maintenance report': 'Zeitformat für Wartungsbericht',
    "Events for maintenance report": "Ereignisse für Wartungsbericht",
    "Day": "Tag",
    'Month': 'Monat',
    'Year': 'Jahr',
    'Hour': 'Stunde',
    'Minute': 'Minute',
    'Second': 'Sekunde',
    'Show actuations': 'Steuerungen anzeigen',
    'Show alarming devices': 'Alarmierungseinrichtungen anzeigen',
    'Show transmitting devices': 'Übertragungseinrichtungen anzeigen',
    'Show leading zeros for elements': 'führende Nullen für Elemente darstellen',
    'Show leading zeros for zones': 'führende Nullen für Gruppen darstellen',
    'Show event texts': 'Ereignistexte anzeigen',
    'Show silent inputs': 'Stille Eingänge anzeigen',
    'Show silent outputs': 'Stille Ausgänge anzeigen',

    /* setting words */
    "confirm": "okay",
    "Confirm": "Okay",
    "Create": "Erstellen",
    "Cancel": "Abbruch",
    "cancel": "abbruch",
    "reset": "reset",
    "new System": "neue Anlage",

    /* filter */
    "filter": "Filtern",
    "event": "Ereignis",
    "operating elements": "Bedienelemente",
    "Actuation Elements": "Steuerelemente",
    "Customers-ID": "Kunden ID",
    "CustomerNumber": "Kundennummer",
    "SystemKey": "Anlagenschlüssel",
    "CustomerName": "Kundenname",
    "SystemName": "Anlagenname",
    "SystemNumber": "Anlagen-ID",

    "ivalid name": "ungültiger name",
    "ivalid number": "ungültige Nummer",
    "from": "von",
    "to": "bis",


    /* phonenumber */

    "Phonenumber": "Telefonnummer",
    "Fire Alarm": "Brandalarm",
    "Offline": "Offline",

    /* Einreichunterlagen */
    "Postalcode": "Postleitzahl",
    "Degree of protection ": "Stärke des Schutzes",

    /* Menü */
    "Permission": "Erlaubnis",
    "Systemsettings": "Einstellungen",
    "Settings": "Einstellungen",
    "Maintenance report": "Wartungsbericht anzeigen",
    "Event storage": "Ereignisspeicher anzeigen",
    "Show measurement": "Messwerte anzeigen",
    "Show queries": "Abfragen anzeigen",
    "Show releases": "Freigaben anzeigen",
    "Show phonenumbers": "Telefonnummern ansehen",
    "Show plans": "Pläne ansehen",
    "Details on submission documents": "Details zu Einreichunterlagen",
    "Power packs for submission documents": "Netzteile zu Einreichunterlagen",
    "Show devices": "Anzeigeeinrichtungen",
    "Other alarm devices": "Sonstige Arlarmgeräte",
    "Other devices": "Sonstige Geräte",
    "Inputs for submission documents": "Eingänge zu Einreichunterlagen",
    "Type definition submission documents": "Typendefinition Einreichunterlagen",
    "Documentation history": "Anlagendokumentartion History",
    "Simulate Login": "Anmeldung simulieren",
    "Show Plans": "Pläne anzeigen",
    "Create User": "Benutzer erstellen",
    "Create Customer": "Kunde erstellen",
    "Add system": "Anlage hinzufügen",
    "List users": "Alle Benutzer anzeigen",
    "List systems": "Alle Anlagen anzeigen",


    /* Customer Settings */

    "Customersettings": "Einstellungen",
    "Language/Format": "Sprache / Format",
    "Invoice": "Rechnung",

    /* MainSettings */
    "Client name": "Kundenname",
    "Client number of": "Kundennummer von",
    "Save as template": "Als Vorlage speichern",
    "Assigned to": "Zugeordnet zu",

    /* RightsSettings */
    "is allowed to create new users": "Darf weitere Benutzer anlegen",
    "is allowed to create new clients": "Darf weitere Kunden anlegen",
    "Can view all visible systems": "Bekommt alle sichtbaren Anlagen gelistet",
    "is allowed to create and connect systems": "Darf Anlagen anlegen und verbinden",
    "is allowed to add new enablements": "Darf weitere Freigaben vergeben",
    "Authorization level2 allowed": "Berechtigungsstufe 2 erlabuen",
    "Operation only possible if session is enabled on the FDCP": "Bedinung nur möglich, wenn die Sitzung an der BMZ freigegeben",
    "Authorization level3 allowed": "Berechtigungsstufe 3 erlabuen",
    "Resives notifications of lower level systems": "Erhält Nachrichten darunterliegender Anlagen",
    "Hide clients,user and systems of lower level": "Darunterliegende Kunden, Benutzer und Anlagen verbergen",
    "Sub-Admin": "Sub-Admin",
    "Resseler": "Wiederverkäufer",


    /* Language/Format Settings*/
    "Language setting": "Spracheinstellung",
    "Creation date": "Erstelldatum",
    "Creation by user": "Erstellt durch Benutzer",
    "Last Modified": "Zuletzt geändert",
    "Modified by client": "Geändert durch Kunden",
    "Date/time format": "Format Datum/Uhrzeit",

    /* LogoSettings */
    "Customer logo": "Kundenlogo",
    "Use customer logo": "Kundenlogo verwenden",
    "The customer logo should not exceed a pictur height fo 50 pixels and a pucture width of 200 pixels. PNG is supported as file format.": "Das Kundenlogo sollte eine Bildhöhe von 50px und eine Bildbreite von 200px nicht überschreiten. Als Bildformat wird PNG unterstützt.",
    "This customer logl is currently stored an the server": "Dieses Kundenlogo ist derzeit am Server gespeichert",


    /* IonvoiceSettings */
    "Order number": "Bestellnummer",
    "Payment term": "Zahlunskondition",

    /* setting words */
    "Inherited": "Vererbt",
    "Denied": "Verweigert",
    "Maximum upload size: ": "Maximale Hochladegröße: ",

    /* User Settings */

    "Usersettings": "Einstellungen",
    "Genaral": "Allgemein",

    /* MainSettings */
    "Mr": "Herr",
    "Ms": "Frau",
    "Company": "Firma",
    "Salutation": "Anrede",
    "Salutation.m": "Herr",
    "Salutation.f": "Frau",
    "Salutation.c": "Firma",
    "Salutation.other": "-",
    "Academic title": "Titel",
    "User-ID": "Benutzer-ID",
    "First name": "Vorname",
    "Last name": "Nachname",
    "Last login": "letzte Aktivität",
    "Occupation": "Tätigkeit",
    "Landline": "Festnetz",
    "Mobile phone": "Mobilteslefon",
    "Save as tempate": "Als Vorlage speichern",
    "Created by": "Angelegt durch",
    "Last activity": "Zuletzt aktiv",
    "Code authorization level 2": "Code Berechtingungsstufe 2",
    "Code authorization level 3": "Code Berechtingungsstufe 3",
    "Icon color": "Iconfarbe",
    "Indication in case of fire alarm": "Anzeige bei Brandalarm",
    "Icons flashing at": "Icons blinken bei",
    "Fire alarm": "Brandalarm",
    "fire pre-warnings": "Brandvorwarnung",
    "Logoff time permission": "Log-OFF Zeit Berechtigung",
    "Logoff time app": "Log-OFF Zeit App",

    /* Details Settings */

    "days": "Tage",
    "plans": "Pläne",
    "Real-time Menü": "Echtzeitmenü",
    "Red": "Rot",
    "Blue": "Blau",
    "Yellow": "Gelb",
    "Green": "Grün",
    "Black": "Schwarz",


    /* SystemViewSettings */
    "System view": "Anlagenanzeige",
    "Only show systems in case of": "Anlagen nur anzeigen wenn",
    "all": "Alle",
    "range": "Bereich",
    "Fire alarm - manual call point": "Brandalarm - Handfeuermelder",
    "Fire alarm-automatic detector": "Brandalarm - automatischer Melder",
    "Fault-zone": "Störung Gruppe",
    "Fault-actuation": "Störung Steuerung",
    "Fault-alarming device": "Störung Alarmierungseinrichtung",
    "Fault-transmitting device": "Störung Übertragungseinrichtung",
    "Transmitting device activated": "ÜE aktiviert",
    "Transmitting device disabled": "ÜE abgeschaltet",
    "Transmitting device hidden": "ÜE abgeschaltet",
    "REACT session": "REACT-Sitzung ",


    /* Authorization */

    "Authorization Level 2": "Autorisierungsebene 2",
    "Authorization Level 3": "Autorisierungsebene 3",
    "Enter Code": "Bitte Code eingeben",

    /* RightsSettings */

    "is allowed to log in on multiple devices": "Darf sich an mehreren Geräten einloggen",
    "is allowed toto create new users": "Darf weitere Benutzer anlegen",
    "Receives notifications of lover level systems": "Erhält Nachrichten darunterliegender Anlagen",
    "Analogue detector values also avaliable in authorization level 2": "Meldermesswerte auch in Berechtigungsstufe 2 verfügbar",
    "List view in authorizatzon level 1": "Listenanschicht in Berechtigungsstufe 1",

    /* PushSettings */
    "Push": "Benachrichtigung",
    "Fire-pre-warning": "Brandvorwarnung",
    "Test condition": "Prüfzustand",
    "Test Condition": "Prüfzustand",
    "Test activation": "Testaktivierung",
    "Silenct actuations": "stille Steuerungen",
    "Silent techn. msg.": "Stille techn. Meld.",
    "Logical number": "Logische Nummer",
    "Event text": "Ereignistext",
    "Event time": "Ereigniszeit",
    "Zone text": "Gruppentext",
    "Element text": "Elementtext",
    "Tone at ": "Ton bei ",
    "Standard tone of end device": "Standardton des Endgerätes",
    "Fire 1 (sawtooth)": "Feuer 1 (Sägezahn)",
    "Fire 2 (beep)": "Feuer 2 (Beep)",
    "Fire 3 (triple beep)": "Feuer 3 (3-fach Beep)",
    "Information tone": "Hinweis",
    "Fire 4 (bell)": "Feuer 4 (Glocke) ",
    "none": "kein",
    "Text to speach": "Sprachausgabe",
    "begin": "Begin",
    "end": "Ende",
    "priority": "Priorität",

    /* EmailSettings */
    "E-mail notifications at": "E-Mail-Nachrichten bei",
    "New client": "Neuer Kunde",
    "New resseler": "Neuer Wiederverkäufer",
    "New user": "Neuer Benutzer",
    "New system": "Neuer Anlage",
    "New enablement": "Neuer Freigabe",
    "New GPS area": "Neuer GPS-Bereich",

    /* Language/Fromat Settings */
    "Language/format": "Sprache / Format",
    "Language": "Sprache",
    "Remarks": "Anmerkung",
    "Faults": "Störungen",
    "Disablements": "Abschaltungen",
    "Technical messages": "Technische Meldungen",
    "Technical Message": "Technische Meldung",
    "Activations": "Aktivierungen",
    "Extinguishing events": "Löschereignisse",
    "Fire alarms": "Brandalarme",
    "Test conditions": "Prüfzustande",
    "Test activations": "Testaktivierungen",
    "Confirmations": "Rückmeldungen",

    /* Events */

    "Tech. messages": " Tech. Meldungen",
    "Interference": "Störungen",
    "Shutdowns": "Abschaltungen",
    "Test stands": "Prüfzustand",
    "System": "Anlage",
    "system": "Anlage",
    "Event": "Ereignis",
    "Elements": "Listen",

    /* Groups */
    "Group": "Gruppen",
    "Control": "Steuerungen",
    "Extinguish groups": "Löschbereiche",
    "extinguish groups": "Löschbereiche",
    "Alarm device": "Alamier. einr.",
    "Transmission device": "Übertragungs. einr.",
    "Groupstorage": "Ereignisspeicher",

    /* SystemList */
    "Controlelements": "Kontrollelemente",

    /* Reports */

    'Maintenance': "Wartungsbericht",
    'Event memory': "Ereignisspeicher",
    'Analogue': "Messwerte",
    'Queries': "Abfragen",

    /* Queries Report */
    "Time": "Zeit",
    "First element": "Erstes Element",
    "Last element": "Letztes Element",
    "First zone": "Erstes Gruppe",
    "Last zone": "Letztes Gruppe",
    "Executed command": "Abgesetztes Kommando",


    /* AnalogueValues Report  */

    "Zone": "",
    "Element.": "Ele",
    "Current value": "Akt.",
    "Drit%": "Vers%",
    "Alarming value": "AL-W",
    "+12MNTH": "AKT.+12",
    "Maint": "REV",
    "Element type": "Elementtyp",
    "-1MNTH": "AKT.-1",
    "-2MNTH": "AKT.-2",
    "-3MNTH": "AKT.-3",
    "-4MNTH": "AKT.-4",
    "-5MNTH": "AKT.-5",
    "-6MNTH": "AKT.-6",


    /* EventMemory Report */
    "ID.": "ID",
    "No.": "Nr.",
    "Ele": "Ele",
    "Detailed text": "Detailtext",
    "Text1": "Text 1",
    "Text2": "Text 2",
    "Member": "TLN",

    /*  */
    "Check at": "Prüfung am",

    /* Plan */

    "Page": "Seite",
    "Floor": "Ebene",
    "already exist": "existiert bereits",
    "please type in a name": "Bitte geben Sie einen Namen ein",
    "new Plan page": "Neue Plan Seite",
    "Plan image": "Plan Foto",
    "Search": "Suche",
    "out of range": "außerhalb vom Bereich",
    "Close": "Schließen",
    "Delete": "Löschen",
    "Rotation": "Drehung",
    "Left/Right": "Links/Rechts",
    "Top/Bottom": "Oben/Unten",
    "you can`t set a detector here": "es kann kein Melder gesetzt werden",
    "please choose a device": "bitte Endgerät wählen",
    "Device": "Endgerät",

    /* Filter */
    "type": "Type",
    "Type": "Type",
    "Modified at": "Geändert am",
    "Postcode": "Postleitzahl",
    "Post code": "Postleitzahl",
    "User ID": "Benutzer ID",
    'Status': 'Status',

    /*  */
    "No Content": "Keine Element vorhanden",
    /* List Pagination */
    "Items per Page": "Elemente pro Seite",


    /* FilterCategory */
    //"TD_None": "Keine",
    "FC_Alarm": "Alarme",
    "FC_Fault": "Störungen",
    "FC_Disablement": "Abschaltungen",
    "FC_TechnicalMessage": "Technische Meldungen",
    "FC_ActiveOutput": "Aktive Ausgänge",
    "FC_Confirmation": "Rückmeldungen",
    "FC_ExtinguishingEvent": "Löschereignisse",
    "FC_TestAlarm": "Test Alarme",
    "FC_TestCondition": "Prüfzustand",
    "FC_FaultAlarm": "Störungs Alarme",
    "FC_Status": "Status",
    "FC_All": "Alle",

    "OPER_RANGE_SELECTED": "Auswahl",
    "OPER_RANGE_BEGIN": "Auswahl beginnen",
    "OPER_RANGE_END": "Auswahl beenden",
    // "OP_range_selected": "Auswahl",
    // "OP_range_begin": "Auswahl beginnen",
    // "OP_range_end": "Auswahl beenden",

    // "OP_disable": "abschalten",
    // "OP_enable": "einschalten",
    // "OP_activate": "aktivieren",
    // "OP_beginTest": "Prüfzustand",
    // "OP_endTest": "Prüfzustand beenden",
    // "OP_silence": "abstellen",
    // "OP_reactivate": "wieder aktivieren",

    // "OP_reset": "rückstellen",
    // "OP_manual": "manueller Betrieb",
    // "OP_endManual": "Standard Betrieb",
    // "OP_spareTank": "Umschaltung Reservebetrieb",
    // "OP_endSpareTank": "Normalbetrieb",
    // "OP_testPreAlarm": "Vor-Alarmierung testen",
    // "OP_endTestPreAlarm": "Vor-Alarmierung Test beenden",
    // "OP_testEvacuationAlarm": "Räumungsalarm testen",
    // "OP_endTestEvacuationAlarm": "Räumungsalarm Test beenden",
    // "OP_testSign": "Leuchtwarnschild testen",
    // "OP_endTestSign": "Leuchtwarnschild Test beenden",
    // "OP_testAlteration": "Umsteuerung testen",
    // "OP_endTestAlteration": "Umsteuerung Test beenden",
    // "OP_testPressureRelief": "Überdruckentlastung testen",
    // "OP_endTestPressureRelief": "Überdruckentlastung Test beenden",
    // "OP_reactivatePreAlarm": "Vor-Alarmierung wieder aktivieren",
    // "OP_silencePreAlarm": "Vor-Alarmierung abstellen",

    /* System Documentation Settings */

    'Monitored object': 'Überwachtes Objekt',
    'User of system': 'Anlagenbetreiber',
    'System installer company': 'Errichterfirma',
    'Electrical installer company': 'Installationsfirma',
    'Degree of protection': 'Schutzumfang',
    'Transmission zones': 'Übertragungsgruppen',
    'Alarm transmission': 'Alarmübertragung',
    "Dates": 'Termine',
    'System documentation': 'Einreichunterlagen',
    'Delay time': 'Interventionszeit',
    'Actuated fire protection systems': 'Angesteuerte Brandschutztechnische Anlagen',
    "Name": 'Name',
    "Use": 'Nutzung',
    'Fire detection system used': 'Verwendetes Brandmeldesystem',
    'Tel. no.': 'Tel.Nr.',
    'Person in charge': 'Sachbearbeiter',
    'Prescribed by authority': 'Behördlich vorgeschrieben',
    "Authority": 'Behörde',
    "Number": 'Zahl',
    "Date": 'Datum',
    'Fire protection strategy created by': 'Brandschutzkonzept erstellt von',
    'Fire brigade in charge': 'Zuständige Feuerwehr',
    'Works fire brigade': 'Betriebsfeuerwehr',
    'New installation': 'Neuinstallation',
    "Expansion": 'Erweiterung',
    "Change": 'Änderung',
    'Area monitored by fire detection system [m²]': 'Überwachungsfläche der BMA [m²]',
    'Number of fire areas': 'Anzahl der Brandabschnitte',
    'Number of branch lines - transmission zones': 'Anzahl Abgezweigte Stiche- Übertragungsgruppen',
    "Automatic": 'Automatisch',
    'Manual:': 'Manuell:',
    'Additional text field manual:': 'Zusatztext Feld Manuell:',
    'Date of first decision': 'Erstbescheiddatum',
    'Start of planning approx.:': 'Planungsbeginn ca:',
    'Start of installation approx.:': 'Montagebeginn ca:',
    'Commissioning approx.:': 'Inbetriebnahme ca:',
    'List of zones for fire detector zones': 'Bedienungsgruppenverzeichnis für Brandmeldegruppen',
    'List of actuations 1': 'Steuerungsgruppenverzeichnis 1',
    'List of actuations 2': 'Steuerungsgruppenverzeichnis 2',
    'List of actuations 3': 'Steuerungsgruppenverzeichnis 3',
    "Inputs": 'Eingänge',
    'Submission - Networking': 'Einreichung Vernetzung',
    'Fire prevention plans': 'Brandschutzpläne',
    'Confirmation that work has been carried out by certified specialist company': 'Bestätigung über Ausführung durch zertifizierte Fachfirma',
    'Exploration time after transmitting device': 'Erkundungszeit nach ÜE',
    'Reaction time after transmitting device': 'Reaktionszeit nach ÜE',
    'Time of disablement': 'Abschaltzeitpunkt',
    'Sprinkler system': 'Sprinkleranlage',
    'Full-time works fire brigade': 'Hauptberufliche Betriebsfeuerwehr',
    'Gas extinguishing system': 'Gaslöschanlage',
    'Smoke and heat exhaust system': 'RWA',
    'Forced ventilation system': 'DBA',
    'Electro acoustic emergency warning system': 'ENS',
    'Pre-piloted sprinkler system': 'Vorgesteuerte Sprinkleranlage',
    'Area monitored by fire detection system [m2]': ' Überwachungsfläche der BMA [m²]',
    "Transmission Zones": "Übertragungsgruppen",
    "Additional text field manual": "Zusatztext Feld Manuell",
    "Date of first decison": "Erstbescheiddatum",
    "Commisioning approx.:": "Inbetriebnahme ca:",
    "Time fo disablement": "Abschaltzeitpunkt",
    "Pre-piloted sprinker system": "Vorgesteuerte Sprinkleranlage",
    "Actuated fire protection Systems": "Angesteuerte Brandschutztechnische Anlagen",


    /* Pagination */
    'item per page': 'element per Seite',


    /*  */
    "load": "lade",
    "Load": "Lade",
    "Measures": "Messwerte",

    /* other-alarm-devices  */

    "Quantity": "Anzahl",
    "Device category": "Art",


    /* power-units-Documentation */
    "Power unit type": "Netzteiltyp",
    "Location of installation": "Montageort",
    "Nominal current [A]": "Nennstrom [A]",
    "Current consumption at mains fault [mA]": "Maximale Stromaufnahme [mA]",
    "Bridging time [h]": "Überbrückungszeit [h]",
    "Nominal voltage of stand-by battery [V]": "Notstrombatterie Nennspannung [V]",
    "Capacity of stand-by battery [Ah]": "Notstrombatterie Kapazität [Ah]",
    "Number fo stand-by batteries [pcs.]": "Anzahl Notstrombatterien [Stk.]",
    "Nominal voltage secondary side": "Nennspannung sekundär [V]",

    /* inputs-system-Documentation */
    "Input no.": "Eingangs Nr.",
    "Function module type": "Funktionsmodultyp",
    "Zone number": "Gruppennummer",
    "Element number": "Elementnummer",
    "Gruppentext": "Gruppentext",
    "Actuated by device": "Angesteuert von Gerät",

    /* display device */

    "Display device type": "Anzeigeeinrichtung Typ",
    "Control panel type": "Zentralentyp",
    "Control panel number": "Zentralennummer",
    "connected to:": "angeschlossen an:",
    "Standort": "",
    "interface type": "Schnittstellentyp",
    "Connections": "Verbindungen",


    /* Documentation-history */

    "Compare.": "Vgl.:",

    "System ID": "Anlagen ID:",

    "XML Filename": "XML Dateiname:",

    "Documentation": "Doku:",

    "Current Status": "akt. Status:",

    "Created": "Erstellt:",

    "finished": "Fertiggestellt:",

    "user": "User:",

    /* System-List */
    "OperatingElements": "Bedienelemente",
    "ActuationElements": "Steuerelemente",

    /* Customer-List */
    "PostalCode": "PLZ",
    "Address1": "Anschrift 1",
    "Address2": "Anschrift 2",




    /* Release-settings */
    "View": "Anzeige",
    "Operation": "Bedienung",

    "Zones": "Gruppen",
    "Actuations": "Steuerungen",
    "Alarming Devices": "AEs",
    "Transmitting devices": "ÜEs",
    "Flooding zones": "Löschbereiche",

    //CWI
    "Austria": "Österreich",
    "Switzerland": "Schweiz",
    "Germany": "Deutschland",

    "CUSTOMERS_ID": "Kunden-ID",
    "CUSTOMERS_NAME": "Kundenname",
    "CUSTOMERS_NAME_TEMPLATE": "Kundenname VORLAGE",
    "CUSTOMERS_NAME2": "Kundenname 2",
    "CUSTOMERS_NAME3": "Kundenname 3",
    "CUSTOMERS_ADDRESS1": "Anschrift 1",
    "CUSTOMERS_ADDRESS2": "Anschrift 2",
    "CUSTOMERS_POSTAL_CODE": "PLZ",
    "CUSTOMERS_CITY": "Ort",
    "CUSTOMERS_COUNTRY": "Land",

    "REPORT_MAINTENANCE_LOG_ELEMENTS": "Elemente anzeigen",
    "REPORT_MAINTENANCE_LOG_ELEMENTS_TESTED": "Nur geprüfte",
    "REPORT_MAINTENANCE_LOG_ELEMENTS_UNTESTED": "Nur ungeprüfte",
    "REPORT_MAINTENANCE_LOG_DATE": "Wartung durchgeführt",
    "REPORT_MAINTENANCE_LOG_EVENT": "Prüfereignis",

    "SYSTEM_DETAILS": "Details",

    "Operate system": "Anlage bedienen",
    "Enter system number": "Geben Sie die Anlagen-ID an",
    "Could not find system with system number '%{systemNumber}'": "Keine Anlage mit Anlagen-ID %{systemNumber} gefunden",

    "AUTHORIZATION_BLOCKED_MESSAGE": "Der Benutzer %{username} mit der E-Mail Adresse %{email} ist bereits in Berechtigung. Daher ist ihr Einstieg derzeit nicht möglich.",
    "AUTHORIZATION_BLOCKED_PHONE_MESSAGE": "Der Benutzer %{username} mit der Mobiltelefonnummer %{phoneNumber} ist bereits in Berechtigung. Daher ist ihr Einstieg derzeit nicht möglich.",

    "Authorization failed": "Fehler bei der Autorisierung:",
    "Authorization code invalid": "Autorisierungscode ungültig",

    "Dismiss": "Schließen",


    "Share type": "Art der Freigabe",

    "Show shares": "Freigaben anzeigen",

    "SHARE_TYPE_0": "Standardfreigabe",
    "SHARE_TYPE_1": "bearbeitet",
    "SHARE_TYPE_2": "erweitert",

    "Valid for": "gültig bis",
    "expired": "abgelaufen",

    "GROUP_RANGE_PICKER_0": "Gruppen",
    "GROUP_RANGE_PICKER_1": "Steuerungen",
    "GROUP_RANGE_PICKER_2": "Alarmier.Einr.",
    "GROUP_RANGE_PICKER_3": "Übertrag.Ein",
    "GROUP_RANGE_PICKER_44": "Löschbereiche",

    "Overriding system defaults": "Sie umgehen die Einstellungen der Anlage",
    "This setting will overrule, what has been on the system. Are you sure, you want to do this": "Diese Einstellung setzt die Einstellung an der Anlage außer Kraft. Wollen Sie das wirklich tun?",

    "TD_DISPLAY_ALL": "Ereignisart wählen",
    "TD_DISPLAY_ACTIVE_UE": "ÜE aktiviert",
    "TD_DISPLAY_DISABLED_UE": "ÜE abgeschaltet",

    "SYSTEM_VIEW_LABEL_AREA": "im Bereich von",
    "SYSTEM_VIEW_LABEL_RESET": "zurücksetzen",
    "SYSTEM_VIEW_LABEL_FILTER_ON_SERVER": "Anlagen aus der Anlagenliste entfernen",

    "Event display": "Ereignisanzeige"

};

export default de;

