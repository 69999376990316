type Window = {
    env: {
        APP_SERVICE_URL: string;
        APP_HEARTBEAT_INTERVAL: number;
        APP_VERSION?: string;
    };
};

const { env } = window as unknown as Window;

export { env };