import { useL10n } from "@ews/react-localization-context";

import { SystemPlan } from "@ews/react-data";
import { IonButton, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonList, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { trash } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import SettingsDropFile from "../../../../../Settings/SettingsComponents/SettingsDropFile/SettingsDropFile";
import SettingsNumberInput from "../../../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import SettingsTextInput from "../../../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { loadImageBounds } from "../../Floors";
import style from "./FloorEdit.module.scss";
import { FloorEditProps } from "./types";

const FloorEdit: React.FC<FloorEditProps> = ({
    floor: current,
    floors = [],
    systemId,
    onDismiss,
    onDelete,
    onModified
}) =>
{

    const [_floor, setFloor] = useState<SystemPlan | Record<string, any>>({});

    const { translate: t } = useL10n();
    const modal = useRef<HTMLIonModalElement>(null);
    const [error, setError] = useState("");

    const [size, setSize] = useState<{ width?: number, height?: number; }>({});


    function onWillDismiss()
    {
        onDismiss(false);
        close();
    }
    function floorNumberExist(floor: number)
    {
        const _floor = floors.filter(p => p.floor === floor);
        return Boolean(_floor.length);
    }

    function _delete()
    {
        if (onDelete && current?.id) {
            onDelete(current.id);
        };
        close();
    }

    function close()
    {
        setFloor({});
        modal.current?.dismiss();
    }

    function setSubtraktionIndicator(height: number, width: number)
    {
        if (height <= 100 && width <= 100) {
            return 0;
        }
        if (((height >= 100 && height <= 200) && (width >= 100 && width <= 200))) {
            return 1.5;
        }
        else {
            return (Math.floor(width / (width / 10)));
        }
    }

    useEffect(() =>
    {
        if (current) {
            const { floor, label, image } = current;
            setFloor({ floor, label, image });
            loadImageBounds(image!, (size) =>
            {
                const [height, width] = size;

                const imageSubtraktionIndicator = setSubtraktionIndicator(height, width);
                setSize({ width: width / imageSubtraktionIndicator, height: height / imageSubtraktionIndicator });
            });
        }
    }, [current]);

    return <IonModal
        ref={modal}
        className={style.newSystemModal}
        isOpen={_floor && "label" in _floor}
        onWillDismiss={onWillDismiss}>
        <IonHeader>
            <IonToolbar>
                <div
                    className={style.header}
                >
                    <IonTitle>{`${`${t("Edit")} ${t("Page")}`}`}</IonTitle>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonList
            className="ion-padding"
            color="primary">
            <SettingsTextInput
                text={"Name"}
                value={_floor?.label || ""}
                debounce={500}
                onChange={(label) =>
                {
                    setError(!label || !label.length ? t("please type in a name") : '');
                    setFloor({ ..._floor, label });
                }}
            />
            <SettingsNumberInput
                text={"Floor"}
                value={_floor?.floor || 0}
                debounce={100}
                onChange={(floor) =>
                {
                    setError(floorNumberExist(floor) ? t("already exist") : '');
                    setFloor({ ..._floor, floor });
                }} />

            {_floor?.image ?
                <IonItem
                    style={{ minHeight: "143px" }}
                >
                    <IonFab vertical="top" horizontal="end">
                        <IonFabButton
                            color={"danger"}
                            size="small"
                            onClick={() => setFloor({ ..._floor, image: undefined })}
                        >
                            <IonIcon icon={trash} />
                        </IonFabButton>
                    </IonFab>
                    <div className={style.image}>
                        <img
                            className={style.img}
                            width={(size?.width || 100)}
                            height={(size?.height || 100)}
                            src={_floor.image}
                            loading="lazy"
                            alt=""
                        />
                    </div>
                </IonItem>
                :
                <SettingsDropFile
                    text={"Plan image"}
                    accept="image/*"
                    maxSize={8_000_000}
                    info="Maximum upload size:"
                    //onError={(error) => console.log(error)}
                    onFile={function (file: File): void
                    {
                        try {
                            const reader = new FileReader();
                            reader.onload = (e) =>
                            {
                                const base64String = e.target?.result;
                                if (base64String) {
                                    setFloor({ ..._floor, image: base64String.toString() });
                                    if (error.length) setError("");
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                        catch (err) {
                            setError(t("can not upload the File"));
                        }
                    }}
                />
            }
            <IonItem lines={error.length ? "full" : "none"}>
                <div className={style.error}>
                    <IonText color={"danger"}>{error}</IonText>
                </div>
            </IonItem>
        </IonList>
        <div className={`${style.buttonContainer} ${style.marginBottom}`}>
            <IonButton
                slot="start"
                color={"danger"}
                onClick={() => close()}>
                {t("Cancel")}
            </IonButton>
            <IonButton
                color={"danger"}
                onClick={() => _delete()}>
                {t("Delete")}
            </IonButton>
            <IonButton
                slot="end"
                color={"success"}
                onClick={() =>
                {
                    const data = { ...current, ..._floor } as SystemPlan;
                    if (onModified) { onModified(data); }
                    close();
                }}>
                {t("Save")}
            </IonButton>
        </div>
    </IonModal>;

};


export default FloorEdit;