import { generateId, IonCol, IonItemOption, IonItemOptions, IonLabel } from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { EventPriority, EventType, Events } from "../Event/types";
import FilterList from "../FilterList/FilterList";
import { EventCategoryIcon } from '../Icons';

import
{
    SystemStatusIcon,
    SystemTypeIcon
} from '../Icons';

import List from "../List/List";
import Sidebar from "../Navigation/Sidebar/System";
import { useIsMobile } from "../Responsive";

import { useL10n } from "@ews/react-localization-context";
import { System, SystemStatus, SystemType } from "@ews/react-data";
import { RTMCategory } from "@ews/zlt-events";
import Checkbox from "../Checkbox/Wrapper";
import { useMySystems, useTypeAheadSystemList } from "../ReactData/system";
import SettingsTextInput from "../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { Filter } from "./Reports/types/reportTypes";
import systemStyle from './SystemList.module.scss';
import SystemMap from "./SystemMap";
import { useMyNotifySystems } from "../Login/LoginProvider";
import { usePersistentState } from "../Persistence";
import { useNamedRoutes } from "../NamedRoutes";

const eventKindTypeList: number[] = [1, 2, 4, 16];
const sumEventKindType = [...eventKindTypeList].reduce((total, current) => total | current);

type ListFilter = {
    name?: string;
    systemType?: string;
    "customer.customerNumber"?: string;
    "customer.name"?: string;
    tree?: string,
    systemNumber?: string;
    eventStatus?: number;
    status?: string;
    systemKey?: string;
    _timestamp?: number;
};

const standardFilter: Filter<ListFilter> = {
    sortCriteria: "priority",
    sortOrder: "DESC",
};

const SystemList: React.FC<{
    customerTree?: string,
    type: "list" | "map";
    isFilterOpen?: boolean,
    onIsOpen?: (status: boolean) => void;
}> = ({
    customerTree,
    type,
    isFilterOpen,
    onIsOpen
}) =>
    {
        const { translate: t } = useL10n();
        const { generate } = useNamedRoutes();
        const filterDebounceTime = 500;
        const refreshOnNotifySystem = useMyNotifySystems();


        const mobileView = useIsMobile();
        const hidefilterDetails = false;

        type SystemStatus_ = keyof typeof SystemStatus;
        type SystemType_ = keyof typeof SystemType;

        const systemStatusAvailable: SystemStatus_[] = Object.keys(SystemStatus).filter(status => isNaN(Number(status))) as unknown[] as SystemStatus_[];
        const systemTypeAvailable: SystemType[] = Object.values(SystemType).sort();

        const [selectedStatus, setSelectedStatus] = useState<SystemStatus_[]>(systemStatusAvailable);
        const [selectedType, setSelectedType] = useState<SystemType_[]>(systemTypeAvailable as SystemType_[]);
        //const [currentState, setState] = useState<Filter<ListFilter>>({ ...standardFilter, _timestamp: refreshOnNotifySystem.timestamp });
        const [currentState, setState] = usePersistentState<Filter<ListFilter>>('systems:filter', { ...standardFilter, _timestamp: refreshOnNotifySystem.timestamp });

        if (customerTree) {
            currentState.tree = customerTree;
        } else {
            delete currentState.tree;
        }

        const result = useMySystems(currentState);

        const handleSelectEvent = (eventKindtype: number, checked: boolean) =>
        {
            const selectedEvent = currentState.eventStatus ? currentState.eventStatus : 0b00;
            const currentFilter = { ...currentState };

            eventKindtype = checked ? eventKindtype | selectedEvent : eventKindtype ^ selectedEvent;

            if (eventKindtype === 0) delete currentFilter!.eventStatus;
            else currentFilter.eventStatus = eventKindtype;

            setState({ ...currentFilter });
        };

        useEffect(() =>
        {
            setState((current) =>
            {
                return refreshOnNotifySystem.timestamp !== current._timestamp ? { ...current, _timestamp: refreshOnNotifySystem.timestamp } : current;
            });

        }, [refreshOnNotifySystem]);


        const rows = result.results.map((system) =>
        {
            if (system.filterOnClient) {
                (system as any).className = systemStyle.disabled;
            } else {
                (system as any).link = generate("system:events", { "id": system.id });
            }


            return system;
        });

        return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>

            <FilterList
                //isDetailsHidden={hidefilterDetails}
                //onDetailOpen={setHideFilterDetails}
                onIsOpen={onIsOpen}
                isOpen={isFilterOpen}
                isDetailsHidden={hidefilterDetails}
                onReset={() => setState(standardFilter)
                }
                details={<>
                    <IonCol>
                        <div>
                            <IonLabel>{t("Type")}</IonLabel>
                            <div
                                className={systemStyle.filterTypesContainer}
                            >
                                {systemTypeAvailable.map((currentType, index) =>
                                {
                                    return <div key={currentType + index} className={systemStyle.iconContainer}>

                                        <Checkbox
                                            checked={selectedType.indexOf(currentType as any) !== -1 ? true : false}

                                            position={
                                                {
                                                    top: -10,
                                                    right: -10
                                                }

                                            }
                                            onClick={(checked) =>
                                            {
                                                let type = selectedType;

                                                const typeKey = currentType as SystemType_;

                                                if (checked && type.indexOf(typeKey) === -1) {
                                                    type.push(typeKey);
                                                } else {
                                                    type = type.filter(t => t !== typeKey);
                                                }

                                                setSelectedType(type);

                                                const currentFilter = { ...currentState };

                                                if (type.length) {
                                                    currentFilter.systemType = type.join(",");
                                                } else {
                                                    delete currentFilter.systemType;
                                                }

                                                setState(currentFilter);
                                            }}>

                                            <SystemTypeIcon type={currentType}></SystemTypeIcon>
                                            <IonLabel>{currentType}</IonLabel>
                                        </Checkbox>

                                    </div>;
                                })}
                            </div>
                        </div>
                    </IonCol>
                    <IonCol>
                        <div>
                            <IonLabel>{t("Status")}</IonLabel>
                            <div
                                className={systemStyle.filterStatusContainer}
                            >
                                {systemStatusAvailable.map((currentStatus, index) =>
                                {
                                    return <div key={currentStatus + index} className={systemStyle.iconContainer}>
                                        <Checkbox
                                            position={{
                                                top: -10,
                                                right: -10
                                            }}
                                            checked={selectedStatus.indexOf(currentStatus as SystemStatus_) !== -1 ? true : false}
                                            onClick={(checked) =>
                                            {
                                                let status = selectedStatus;

                                                const statusKey = currentStatus as SystemStatus_;

                                                if (checked && status.indexOf(statusKey) === -1) {
                                                    status.push(statusKey);
                                                } else {
                                                    status = status.filter(st => st !== statusKey);
                                                }

                                                setSelectedStatus(status);
                                                const currentFilter = { ...currentState };

                                                if (status.length) {
                                                    const statusValues = status.map((key) => SystemStatus[key]);
                                                    currentFilter.status = statusValues.join(",");
                                                } else {
                                                    delete currentFilter.status;
                                                }

                                                setState(currentFilter);
                                            }}>
                                            <SystemStatusIcon className={systemStyle.iconSize} status={SystemStatus[currentStatus]} />
                                        </Checkbox>
                                    </div>;
                                })}
                            </div>
                        </div>

                    </IonCol>
                    <IonCol>
                        <div>
                            <IonLabel>{t("Event")}</IonLabel>
                            <div
                                className={systemStyle.filterStatusContainer}
                            >
                                {eventKindTypeList.map((eventKindType, index) =>
                                {
                                    let isChecked = false;
                                    if (eventKindType !== sumEventKindType && currentState.eventStatus) {
                                        let byte = eventKindType & currentState.eventStatus;
                                        isChecked = Boolean(byte);
                                    }
                                    else {
                                        isChecked = currentState.eventStatus === sumEventKindType;
                                    }

                                    return <div key={eventKindType + index} className={systemStyle.iconContainer}>
                                        <Checkbox
                                            position={{
                                                top: -10,
                                                right: -10
                                            }}
                                            checked={isChecked}
                                            onClick={(checked) => handleSelectEvent(eventKindType, checked)}
                                        >
                                            <EventCategoryIcon className={systemStyle.iconSize} key={`event-${eventKindType}`} category={`${RTMCategory[eventKindType]}` as EventType} />
                                        </Checkbox>

                                    </div>;
                                })}
                            </div>
                        </div>
                    </IonCol>
                </>} >

                <IonCol>
                    <SettingsTextInput
                        id="systemName"
                        text={"SystemName"}
                        debounce={filterDebounceTime}
                        value={currentState.name}

                        onChange={(data) =>
                        {
                            const currentFilter = { ...currentState };

                            if (data.length) currentFilter.name = data;
                            else delete currentFilter.name;

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol>

                    <SettingsTextInput
                        text={"SystemNumber"}
                        debounce={filterDebounceTime}
                        value={currentState.systemNumber}
                        onChange={(systemNumber) =>
                        {
                            const currentFilter = { ...currentState };

                            //if (currentFilter.page) delete currentFilter.page;
                            if (systemNumber.length) currentFilter.systemNumber = systemNumber;
                            else delete currentFilter.systemNumber;

                            setState(currentFilter);
                        }} />
                </IonCol>
                <IonCol>

                    <SettingsTextInput
                        text={"SystemKey"}
                        debounce={filterDebounceTime}
                        value={currentState.systemKey}
                        onChange={(systemKey) =>
                        {
                            const currentFilter = { ...currentState };

                            //if (currentFilter.page) delete currentFilter.page;
                            if (systemKey.length) currentFilter.systemKey = systemKey;
                            else delete currentFilter.systemKey;

                            setState(currentFilter);
                        }} />
                </IonCol>
                <IonCol>
                    <SettingsTextInput
                        debounce={filterDebounceTime}
                        text={"CustomerName"}
                        value={currentState["customer.name"]}
                        onChange={(customerName) =>
                        {
                            const currentFilter = { ...currentState };

                            if (customerName.length) currentFilter["customer.name"] = customerName;
                            else delete currentFilter["customer.name"];

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol>
                    <SettingsTextInput
                        debounce={filterDebounceTime}
                        text={"CustomerNumber"}
                        value={currentState["customer.customerNumber"]}
                        onChange={(customerNumber) =>
                        {
                            const currentFilter = { ...currentState };

                            if (customerNumber.length) currentFilter["customer.customerNumber"] = customerNumber;
                            else delete currentFilter["customer.customerNumber"];

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
            </FilterList >


            {type === "list" ?
                < List
                    data-testid="list"
                    rows={rows}
                    sort={currentState.sortCriteria ? {
                        key: currentState.sortCriteria,
                        direction: currentState.sortOrder || "ASC"
                    } : undefined}

                    pageSize={result.pageSize}
                    onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}
                    onPageChange={(page) => setState({ ...currentState, page })}
                    onPageSize={(pageSize) => setState({ ...currentState, pageSize })}
                    onReload={() => setState({ ...currentState })}
                    currentPage={result.currentPage}
                    numberOfPages={result.totalCountOfPages}
                    /* maxHeight={mobileView ? "86vh" : "45vh"} */

                    onMenu={(id: string, onMenuToggle: () => void) => <Sidebar onMenuToggle={onMenuToggle} routeParams={{ id }} />}
                    sliderChildren={
                        mobileView ?
                            <IonItemOptions side="start">
                                <IonItemOption color="success">{t("aktivieren")}</IonItemOption>
                            </IonItemOptions>
                            :
                            null
                    }
                    headers={
                        [
                            {
                                title: '[SID_MNU_TAB_INFO_SCR]',//'TD_ZENTRALENNAME',
                                key: 'name',
                                both: true,
                            },
                            // {
                            //     title: 'Alias',
                            //     key: 'alias',
                            //     breakpoint: 1600, up: true,
                            // },
                            {
                                title: 'SystemNumber',
                                key: 'systemNumber',
                                breakpoint: 1500, up: true,
                            },
                            {
                                title: '[SID_SYSTEM_KEY]',
                                key: 'systemKey',

                                option: { key: 'systemType', chooiceList: Object.keys(SystemType).sort().map(k => [k, <SystemTypeIcon type={SystemType[k as keyof typeof SystemType]} />]) },
                                breakpoint: 1500, up: true,
                            },
                            {
                                title: 'OperatingElements',
                                key: 'inputDevices',
                                breakpoint: 1500, up: true,
                            },
                            {
                                title: 'ActuationElements',
                                key: 'outputDevices',
                                breakpoint: 1500, up: true,
                            },
                            {
                                title: 'CustomerNumber',
                                key: 'customerNumber',
                                breakpoint: 1500, up: true,
                                subEntity: 'customer.customerNumber'
                            },
                            {
                                title: 'CustomerName',
                                key: 'customerName',
                                breakpoint: 1500, up: true,
                                subEntity: 'customer.name'
                            },

                            {
                                title: 'Event',
                                key: 'priority',
                                both: true,
                                child: (system: System) => <div className={!mobileView ? systemStyle.eventIcons : ""}>
                                    <SystemStatusIcon status={system.status!} />
                                    {EventPriority.map((e) =>
                                    {
                                        return (system.eventStatus! & Events[e]) ?
                                            <EventCategoryIcon key={`event-${e}`} category={e} />
                                            :
                                            <React.Fragment key={`event-fragment-${e}`} />;
                                    })}
                                </div>,
                            },
                        ]}
                />
                : <SystemMap systems={rows}></SystemMap>
            }
        </div>;
    };

export default SystemList;
