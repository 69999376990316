import { useL10n } from "@ews/react-localization-context";
import { IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useIsMobile } from "../../../../../Responsive";
import globalStyle from '../../../GlobalSettings.module.scss';
import style from '../../SettingsRangeInput.module.scss';
import { SettingsZoneRangeInputProps, Zone } from "./types";
import { InputChangeEventDetail, IonInputCustomEvent } from "@ionic/core";
import { isValidNumber } from "../../isValid";
import { NoDeviceNumber } from "@ews/zlt-events";
import { trailingZeros } from "@ews/zlt-events/dist/utils";

/* 
TODO:   anschauen wie ich das machen kann das ich buchstaben verbieten kann
        momentan funktioniert es nicht ganz 

*/

const SettingsZoneRangeInput: React.FC<SettingsZoneRangeInputProps> = ({
    onChange,
    value = {},
    hidden = false,
    inputLabels,
    labels,
    labelPosition = 'stacked',
    title,
    disabled,
    required,
    lines = undefined,
    position = { parent: "line", children: "line" },
    debounce,
    divider = true
}) =>
{

    //const regex = /\D/g;

    //const includesForbittenLetters = (str: string) => regex.test(str);


    const { translate: t } = useL10n();
    const mobileView = useIsMobile();
    const inputStyle = mobileView ? 'mobileInput' : 'webInput';

    value.startGroup = value.startGroup || "1";
    value.startElement = trailingZeros(parseInt(value.startElement || "1"), 3);
    value.endGroup = value.endGroup || `${0xffff}`;
    value.endElement = trailingZeros(parseInt(value.endElement || `${0xff}`), 3);

    const [zone, setZone] = useState<Zone>(value);

    function handleChange(value: IonInputCustomEvent<InputChangeEventDetail>["detail"]["value"], type: keyof Zone)
    {

        if (!value) return;

        //if (includesForbittenLetters(value)) value.replace(regex, "");

        if (Number(value) < 0 || Number.isNaN(Number(value))) return;

        let valid;

        if (type === "startGroup" || type === "endGroup") {
            valid = type === "startGroup" ? isValidNumber(value, zone.endGroup || Infinity) : isValidNumber(zone.startGroup || Infinity, value);
            if (!valid) value = type === "startGroup" ? zone.endGroup! : zone.startGroup;
            value = `${Math.min(parseInt(value || ""), NoDeviceNumber)}`;
        }
        if (type === "startElement" || type === "endElement") {
            valid = type === "startElement" ? isValidNumber(value || 0, zone.endElement || Infinity) : isValidNumber(zone.startElement || 0, value || Infinity);
            if (!valid) value = type === "startElement" ? zone.endElement! : zone.startElement;

            value = trailingZeros(Math.min(parseInt(value || ""), 255), 3);
        };

        const currentZone = { ...zone };

        currentZone[type! as keyof typeof zone] = value;

        setZone(currentZone);

        const startZoneElement = currentZone.startGroup ? `${currentZone.startGroup}${currentZone.startElement ? `/${currentZone.startElement}` : ''}` : undefined;
        const endZoneElement = currentZone.endGroup ? `${currentZone.endGroup}${currentZone.endElement ? `/${currentZone.endElement}` : ''}` : undefined;

        const params = { startZoneElement, endZoneElement };

        onChange(params);
    }


    return <IonItem
        hidden={hidden}
        lines={lines}
        className={`${globalStyle.setting}`}>
        <div className={style.row}>

            {title ? <IonLabel
                style={{ width: "fit-content", margin: "0 auto" }}
            >
                {t(title)}
            </IonLabel> : null}

            <div>
                <section>
                    {labels?.start ?
                        <IonText className={style.subLabel}>{t(labels.start)}</IonText>
                        : null}
                    <div style={{ display: "flex" }}>
                        <IonInput
                            debounce={debounce}
                            onFocus={(e) => (e.target as any).select()}
                            required={required?.startGroup}
                            disabled={disabled}
                            type="number"
                            value={zone.startGroup || 0}
                            placeholder={t(inputLabels?.parent || "")}
                            style={{ minHeight: "30px", textAlign: "right", margin: "0 2px 2px 0" }}
                            className={`${style.group} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "startGroup");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "startGroup");
                            }}
                        />
                        <IonLabel style={{ display: "flex" }} class="ion-align-items-center">/</IonLabel>
                        <IonInput
                            debounce={debounce}
                            onFocus={(e) => (e.target as any).select()}
                            required={required?.startElement}
                            disabled={!zone.startGroup || disabled}
                            type="number"
                            value={zone.startElement || 0}
                            placeholder={t(inputLabels?.children || "")}
                            style={{ minHeight: "30px", textAlign: "left", margin: "0 0 2px -6px" }}
                            className={`${style.element} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "startElement");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "startElement");
                            }}
                        />
                    </div>
                </section>
                {divider ? <p className={`${style.devider}`} >{' __ '}</p> : null}
                <section className={style.input}>
                    {labels?.end ?
                        <IonText className={style.subLabel}>{t(labels.end)}</IonText>
                        : null}
                    <div style={{ display: "flex" }}>
                        <IonInput
                            debounce={debounce}
                            onFocus={(e) => (e.target as any).select()}
                            required={required?.endGroup}
                            disabled={disabled}
                            type="number"
                            value={zone.endGroup || NoDeviceNumber}
                            placeholder={t(inputLabels?.parent || "")}
                            style={{ minHeight: "30px", textAlign: "right", margin: "0 2px 2px 0" }}
                            className={`${style.group} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "endGroup");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "endGroup");
                            }}

                        />
                        <IonLabel style={{ display: "flex" }} class="ion-align-items-center">/</IonLabel>
                        <IonInput
                            debounce={debounce}
                            onFocus={(e) => (e.target as any).select()}
                            required={required?.endElement}
                            disabled={!zone.endGroup || disabled}
                            type="number"
                            value={zone.endElement || NoDeviceNumber}
                            placeholder={t(inputLabels?.children || "")}
                            style={{ minHeight: "30px", textAlign: "left", margin: "0 0 2px -6px" }}
                            className={`${style.element} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "endElement");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "endElement");
                            }}
                        />
                    </div>
                </section>
            </div>

        </div>
    </IonItem >;
};

export default SettingsZoneRangeInput;