
import
{
    useLoadingIndicator
} from "../Loading";

import
{
    useTopicData,
    useWebsocket
} from './ReactDataProvider';


import
{
    PaginationRequest, PaginatedResult,
    UserPermissions,
    UserDetails,
    UserNotifications,
    User,
    formatUserDisplayName,
    UserEventDisplay,
    UserSystemDisplay
} from "@ews/react-data";


import
{
    ReactTopics
} from '@ews/websocket-service';

import { useCallback, useEffect, useState } from "react";


export const useMyUsers = (filterCriteria: PaginationRequest) =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    const [userResult, setUsersResult] = useState<PaginatedResult<User>>({
        currentPage: 0,
        pageSize: 0,
        results: [],
        totalCountOfEntries: 0,
        totalCountOfPages: 0
    });

    const handleUser = useCallback((userReceived: User) =>
    {

        setUsersResult((result: PaginatedResult<User>) =>
        {
            result.results = result.results.map((user: User) =>
            {
                return user.id === userReceived.id ? userReceived : user;
            });

            return { ...result };
        });
    }, []);

    const handleResult = useCallback((result: PaginatedResult<User>) =>
    {
        setUsersResult((previousResult: PaginatedResult<User>) =>
        {
            previousResult.results.forEach((user: User) =>
            {
                const topic = ReactTopics.user.withParams({ userId: user.id! });
                provider.unsubscribe(`${topic}`, handleUser);
            });

            result.results.forEach((user: User) =>
            {
                const topic = ReactTopics.user.withParams({ userId: user.id! });
                provider.subscribe(`${topic}`, handleUser);
            });

            return result;

        });
    }, [handleUser, provider]);

    const doRequest = useCallback(async (filterCriteria: PaginationRequest) =>
    {
        handleResult(await provider.fetch<PaginatedResult<User>>(filterCriteria, `${ReactTopics.user}`, loadingToggle));
    }, [handleResult, loadingToggle, provider]);

    useEffect(() =>
    {
        doRequest(filterCriteria);
    }, [filterCriteria, doRequest]);

    return userResult;
};


export const useUserList = () =>
{
    return useTopicData<User>(`${ReactTopics.user}`, {} as User);
};

export const getUserDisplayName = (user: User & UserDetails, includeLogin: boolean = false) =>
{
    return formatUserDisplayName(user.firstName, user.lastName, includeLogin ? user.username : undefined);
};

export const useUserName = (userId: string, includeLogin: boolean = false) =>
{
    const user = useUser(userId);
    return getUserDisplayName(user as User & UserDetails, includeLogin);
};

export const useUser = (userId: string) =>
{
    return useTopicData<User>(`${ReactTopics.user.withParams({ userId })}`, {} as User);
};

export const useUserNotifications = (userId: string) =>
{
    return useTopicData<UserNotifications>(`${ReactTopics.userNotifications.withParams({ userId })}`, {} as UserNotifications);
};

export const useUserDetails = (userId: string) =>
{
    return useTopicData<UserDetails>(`${ReactTopics.userDetails.withParams({ userId })}`, {} as UserDetails);
};


export const useUserPermissions = (userId: string) =>
{
    return useTopicData<UserPermissions>(`${ReactTopics.userPermissions.withParams({ userId })}`, {} as UserPermissions);
};

export const useUserEventDisplay = (userId: string) =>
{
    return useTopicData<UserEventDisplay>(`${ReactTopics.userEventDisplay.withParams({ userId })}`, {} as UserEventDisplay);
};

export const useUserSystemDisplay = (userId: string) =>
{
    return useTopicData<UserSystemDisplay>(`${ReactTopics.userSystemDisplay.withParams({ userId })}`, {} as UserSystemDisplay);
};

export type UserNotifySystems = {
    timestamp: number;
};

export const useUserNotifySystems = (userId: string) =>
{
    return useTopicData<UserNotifySystems>(`${ReactTopics.userNotifySystems.withParams({ userId })}`, { timestamp: Date.now() } as UserNotifySystems);
};

export const useModifyUser = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (userId: string, data: User) => provider.modify(data, `${ReactTopics.user.withParams({ userId })}`, loadingToggle);
};

export const useModifyUserNotifications = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (userId: string, data: UserNotifications) => provider.modify(data, `${ReactTopics.userNotifications.withParams({ userId })}`, loadingToggle);
};

export const useModifyUserPermissions = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (userId: string, data: UserPermissions) => provider.modify(data, `${ReactTopics.userPermissions.withParams({ userId })}`, loadingToggle);
};

export const useModifyUserEventDisplay = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (userId: string, data: UserEventDisplay) => provider.modify(data, `${ReactTopics.userEventDisplay.withParams({ userId })}`, loadingToggle);
};

export const useModifyUserSystemDisplay = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (userId: string, data: UserSystemDisplay) => provider.modify(data, `${ReactTopics.userSystemDisplay.withParams({ userId })}`, loadingToggle);
};

export const useModifyUserDetails = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (userId: string, data: UserDetails) => provider.modify(data, `${ReactTopics.userDetails.withParams({ userId })}`, loadingToggle);
};

export const useRemoveUser = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (userId: string) => provider.delete(`${ReactTopics.user.withParams({ userId })}`, loadingToggle);
};

export const useCreateUser = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (user: User) =>
    {
        return await provider.create(user, `${ReactTopics.userCreate}`, loadingToggle);
    };
};

export const useFetchUserList = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch(filterCriteria, `${ReactTopics.myUsers}`, loadingToggle);
    };
};

export const useTypeAheadUserList = () =>
{
    const provider = useWebsocket();

    return async (filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch(filterCriteria, `${ReactTopics.myUsers}`);
    };
};
