import React, { FC, useState } from 'react';

import Layout from '../components/Layout/System';

import NavbarMain from '../components/Navigation/Navbar/Main';
import SidebarMain from '../components/Navigation/Sidebar/Main';
import StatusbarMain from '../components/Navigation/Status/Main';

import NavbarSystem from '../components/Navigation/Navbar/System';
import SidebarSystem from '../components/Navigation/Sidebar/System';
import StatusbarSystem from '../components/Navigation/Status/System';

import NavbarReports from "../components/Navigation/Navbar/Reports";
import NavbarSettings from "../components/Navigation/Navbar/Settings";

import SystemList from '../components/System/SystemList';

//import { systems } from '../data/systems';
import { addCircleSharp, filterOutline, list, map } from 'ionicons/icons';
import ButtonContainer from '../components/ButtonContainer/ButtonContainer';
import EventOverview from '../components/Event/EventOverview/EventOverview';

import GroupOverview from '../components/Group/GroupOverview/GroupOverview';
import { useNamedRoutes } from '../components/NamedRoutes';
import Plan from '../components/Plan/Plan';
import AnalogueValues from '../components/System/Reports/AnalogueValues/AnalogueValues';
import EventMemory from '../components/System/Reports/EventMemory/EventMemory';
import MaintenanceReport from '../components/System/Reports/MaintenanceReport/MaintenanceReport';
import PhoneNumbers from '../components/System/Reports/Phonenumbers/Phonenumbers';
import ShareSettingsOverlay from '../components/System/Reports/Shares/Component/Settings/SettingsOverlay';
import Shares from '../components/System/Reports/Shares/Shares';
import Details from '../components/System/SystemDetails';
import SettingsOverlay from '../components/System/SystemSettings/SettingsOverlay/SettingsOverlay';

import
{
    useSystem,
    useSystemDetails,
    useSystemRTM,
    useSystemSetup
} from '../components/ReactData/system';

//import { add } from "ionicons/icons";
import NewSystemModal from '../components/System/NewSystem/NewSystemModal';
//import PermissionModal from '../components/System/Permission/PermissionModal';
import { useL10n } from '@ews/react-localization-context';
import { MenuLink } from '../components/Navigation/Sidebar/Sidebar';
import { MenuToggleProps } from '../components/Navigation/types';
import DocumentationsSettingsOverlay from '../components/System/DocumentationsSettings/DocumentationsSettings/DocumentationsSettingsOverlay';
import DisplayDevice from '../components/System/Reports/DisplayDevice/DisplayDevice';
import InputsSystemDocumentation from '../components/System/Reports/InputsSystemDocumentation/InputsSystemDocumentation';
import OtherAlarmDevices from '../components/System/Reports/OtherAlarmDevices/OtherAlarmDevices';
import OtherDevices from '../components/System/Reports/OtherDevices/OtherDevices';
import PowerUnitsDocumentation from '../components/System/Reports/PowerUnitsDocumentation/PowerUnitsDocumentation';
import DocumentationHistory from '../components/System/Reports/SystemDocumentationHistory/SystemDocumentationHistory';
import TypeDefinitionSubmissionDocuments from '../components/System/Reports/TypeDefinitionSubmissionDocuments/TypeDefinitionSubmissionDocuments';
import { ReactClickEvent } from '../components/types';

import
{
    Pane,
    PaneSection
} from '../components/Layout/Pane';

import { RTMDisplay, UserPermissions, permissionAllowed } from "@ews/react-data";
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { useMyPermissions } from '../components/Login/LoginProvider';
import { useCustomer } from '../components/ReactData/customer';
import { useIsMobile } from '../components/Responsive';
import SystemSelect from '../components/System/SystemSelect';
import style from "./System.module.scss";
import { useAuthorization } from '../components/Authorization';
import { AuthorizationLevel } from '@ews/zlt-events';

const SidebarWrapper: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{
    const { translate: t } = useL10n();
    const permissions: UserPermissions = useMyPermissions();

    return (<SidebarMain onMenuToggle={onMenuToggle}>
        {permissionAllowed(permissions.grantSystemAccess!) ?
            <>

                <MenuLink
                    title={t('Add system')}
                    id="newSystem"
                    icon={addCircleSharp}
                />
                <NewSystemModal
                    trigger='newSystem'
                    onDismiss={(e) => onMenuToggle && onMenuToggle(e as unknown as ReactClickEvent)}
                />
            </> : <></>}
    </SidebarMain>);
};



const System: React.FC<{ filterByCustomer?: boolean; }> = ({ filterByCustomer }) =>
{

    const { params } = useNamedRoutes();
    const { id: customerId } = params();
    const customer = useCustomer(customerId || "");

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const mobileView = useIsMobile();
    const [view, setView] = useState<'list' | 'map'>('list');
    const toggle = () =>
    {
        setView(view === 'list' ? 'map' : 'list');
    };

    const permissions = useMyPermissions();

    if (!("listAllSystems" in permissions)) return <></>;
    if (filterByCustomer && (!("tree" in customer))) return <></>;

    const StatusbarWithToggleListMapButton = () =>
    {
        return <>
            <IonFab horizontal='start' className={`${style.pos0}`}>
                <IonFabButton size='small'
                    onClick={toggle}
                >
                    <IonIcon
                        size='small'
                        icon={view === "list" ? map : list}
                    />
                </IonFabButton>
            </IonFab>

            {mobileView ?
                <IonFab horizontal='start' className={`${style.pos1}`}>

                    <IonFabButton
                        size="small"
                        color={'tertiary'}
                        onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <IonIcon icon={filterOutline} />
                    </IonFabButton>
                </IonFab> : null}
            <StatusbarMain></StatusbarMain>
        </>;
    };

    return (
        <>
            {permissionAllowed(permissions.listAllSystems!) ? <Layout sidebar={SidebarWrapper} statusbar={StatusbarWithToggleListMapButton} navbar={NavbarMain} id="system" header={false}>
                <SystemList
                    type={view}
                    customerTree={filterByCustomer ? customer.tree : undefined}
                    isFilterOpen={isFilterOpen}
                    onIsOpen={(open) => setIsFilterOpen(open)}
                />
            </Layout> : <Layout sidebar={SidebarWrapper} statusbar={StatusbarMain} navbar={NavbarMain} id="system" header={false}>
                <SystemSelect />
            </Layout>
            }
        </>
    );
};

const SystemEvent: React.FC<{
    eventTab?: RTMDisplay,
}> = ({
    eventTab = RTMDisplay.eventsTab1,
}) =>
    {
        const { params } = useNamedRoutes();
        const { id } = params();

        const system = useSystem(id!);
        const rtm = useSystemRTM(id!);

        const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

        const mobileView = useIsMobile();

        const Bar = () =>
        {
            return <>
                {mobileView ?
                    <IonFab horizontal='start'>

                        <IonFabButton
                            size="small"
                            color={'tertiary'}
                            onClick={() => setIsFilterOpen(!isFilterOpen)}>
                            <IonIcon icon={filterOutline} />
                        </IonFabButton>
                    </IonFab> : <></>
                }
                <StatusbarSystem></StatusbarSystem>
            </>;
        };

        return (
            <Layout title={system?.name} statusbar={Bar} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:events">
                <Pane vertical>
                    <PaneSection grow>
                        <EventOverview
                            eventTab={eventTab}
                            rtm={rtm}
                            isFilterOpen={isFilterOpen}
                            onIsOpen={(open) => setIsFilterOpen(open)}
                        />
                    </PaneSection>
                    <PaneSection>
                        <ButtonContainer
                            systemId={system.id || ""}
                            rtm={rtm} />
                    </PaneSection>
                </Pane>
            </Layout >
        );
    };

const SystemDetails: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);
    const details = useSystemDetails(id!);
    const rtm = useSystemRTM(id!);

    return (
        <Layout title={system?.name} statusbar={StatusbarSystem} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:system">
            <Pane vertical>
                <PaneSection grow>
                    <Details system={system!} details={details!} />
                </PaneSection>
                <PaneSection>

                    <ButtonContainer
                        systemId={system.id || ""}
                        rtm={rtm} />
                </PaneSection>
            </Pane>

        </Layout>
    );
};

const SystemGroup: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const mobileView = useIsMobile();

    const system = useSystem(id!);
    const setup = useSystemSetup(id!);
    const rtm = useSystemRTM(id!);

    const permissions = useMyPermissions();
    const { authorizationLevel } = useAuthorization();

    if ((!permissions.listViewAccessLevel1) && (authorizationLevel <= AuthorizationLevel.LEVEL1)) return <></>;

    const Bar = () =>
    {
        return <>
            {mobileView ?
                <IonFab horizontal='start'>

                    <IonFabButton
                        size="small"
                        color={'tertiary'}
                        onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <IonIcon icon={filterOutline} />
                    </IonFabButton>
                </IonFab> : <></>
            }
            <StatusbarSystem></StatusbarSystem>
        </>;
    };


    return (
        <Layout title={system?.name} statusbar={Bar} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:elements">
            <Pane vertical>
                <PaneSection grow>
                    <GroupOverview
                        isFilterOpen={isFilterOpen}
                        onIsOpen={(open) => setIsFilterOpen(open)}
                        groups={setup.groups || []}
                    />
                </PaneSection>
                <PaneSection>
                    <ButtonContainer
                        systemId={system.id || ""}
                        rtm={rtm} />
                </PaneSection>
            </Pane>
        </Layout >
    );
};

const SystemPlan: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();
    const rtm = useSystemRTM(id!);

    const system = useSystem(id!);

    return (
        <Layout title={system?.name} statusbar={StatusbarSystem} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:plan">
            <Pane vertical>
                <PaneSection grow>
                    <Plan system={system!} />
                </PaneSection>
                <PaneSection>
                    <ButtonContainer
                        systemId={system.id || ""}
                        rtm={rtm} />
                </PaneSection>
            </Pane>
        </Layout>
    );
};

const SystemShares: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:shares">
                <Shares system={system!} />
            </Layout>
        </>
    );
};

const SystemShareSettings: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id, userId } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:share-settings">
                <ShareSettingsOverlay systemId={id} userId={userId} />
            </Layout>
        </>
    );
};


const SystemEventMemory: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();
    const mobileView = useIsMobile();

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const Bar = () =>
    {
        return <>
            {mobileView ?
                <IonFab horizontal='start'>

                    <IonFabButton
                        size="small"
                        color={'tertiary'}
                        onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <IonIcon icon={filterOutline} />
                    </IonFabButton>
                </IonFab> : <></>
            }
            <StatusbarSystem></StatusbarSystem>
        </>;
    };


    const system = useSystem(id!);

    return (
        <Layout backbutton={true} title={system?.name} statusbar={Bar} sidebar={SidebarSystem} navbar={NavbarReports} id="system:eventmemory">
            <EventMemory
                isFilterOpen={isFilterOpen}
                onIsOpen={(open) => setIsFilterOpen(open)}
                system={system!} />
        </Layout>
    );
};

const SystemAnalogueValues: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    const mobileView = useIsMobile();
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const Bar = () =>
    {

        return <>
            {mobileView ?
                <IonFab horizontal='start'>

                    <IonFabButton
                        size="small"
                        color={'tertiary'}
                        onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <IonIcon icon={filterOutline} />
                    </IonFabButton>
                </IonFab> : <></>
            }
            <StatusbarSystem></StatusbarSystem>
        </>;
    };

    return (
        <Layout backbutton={true} title={system?.name} statusbar={Bar} sidebar={SidebarSystem} navbar={NavbarReports} id="system:analoguevalues">
            <AnalogueValues
                isFilterOpen={isFilterOpen}
                onIsOpen={(open) => setIsFilterOpen(open)}
                system={system!} />
        </Layout>
    );
};

const SystemMaintenanceReport: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:maintenancereport">
            <MaintenanceReport system={system!} />
        </Layout>
    );
};

// const SystemQueries: React.FC = () =>
// {
//     const {params} = useNamedRoutes();
//     const {id} = params();

//     const system = useSystem(id!);

//     return (
//         <>
//             <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:queries">
//                 <Queries system={system!} />
//             </Layout>
//         </>
//     );
// };


const SystemPhoneNumber: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:phonenumber">
            <PhoneNumbers system={system!} />
        </Layout>
    );
};

const SystemDocumentationHistory: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:Documentation-history">
            <DocumentationHistory system={system!} />
        </Layout>
    );
};

const SystemDisplayDevice: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:displaydevice">
                <DisplayDevice system={system!} />
            </Layout>
        </>
    );
};
const SystemPowerUnitsDocumentation: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:powerunitsdocumentation">
                <PowerUnitsDocumentation system={system!} />
            </Layout>
        </>
    );
};
const SystemOtherAlarmDevices: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:otheralarmdevices">
                <OtherAlarmDevices system={system!} />
            </Layout>
        </>
    );
};

const SystemOtherDevices: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:otherdevices">
                <OtherDevices system={system!} />
            </Layout>
        </>
    );
};
const SystemInputsSystemDocumentation: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:inputssystemDocumentation">
                <InputsSystemDocumentation system={system!} />
            </Layout>
        </>
    );
};

const SystemDocumentationsSettingsOverlay: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:Documentationhistory">
                <DocumentationsSettingsOverlay system={system!} />
            </Layout>
        </>
    );
};

const SystemTypeDefinitionSubmissionDocuments: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:typedefinitionsubmissiondocuments">
                <TypeDefinitionSubmissionDocuments system={system!} />
            </Layout>
        </>
    );
};




const SystemSettings: React.FC = () =>
{

    const { params } = useNamedRoutes();
    const { id } = params();
    //const id = "ee17afb2-f438-4336-86f3-dc28c40c54c3";
    //console.log('params id SystemSettings: ', id);
    const system = useSystem(id!);

    return system?.id ? <Layout backbutton={true} title={system?.name} sidebar={SidebarSystem} navbar={NavbarSettings} id="system:settings">

        <SettingsOverlay systemId={system.id || ""} />
    </Layout> : <>loading data</>;
};

export
{
    System,
    SystemAnalogueValues,
    SystemDetails, SystemDisplayDevice, SystemDocumentationHistory, SystemDocumentationsSettingsOverlay, SystemEvent,
    SystemEventMemory,
    SystemGroup, SystemInputsSystemDocumentation, SystemMaintenanceReport, SystemOtherAlarmDevices,
    SystemOtherDevices, SystemPhoneNumber as SystemPhonenumber,
    SystemPlan, SystemPowerUnitsDocumentation, SystemSettings, SystemShareSettings, /*SystemQueries, */ SystemShares, SystemTypeDefinitionSubmissionDocuments
};

