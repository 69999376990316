import { registerDefaults, registerRoutes } from "../components/NamedRoutes";
import { localeRouteParam, Locales } from "./locale";

registerRoutes({
    "home-default": `/${Locales.default}`,
    "home": `/${localeRouteParam}?`,
    "systems": `/${localeRouteParam}/system`,
    "system": `/${localeRouteParam}/system/:id`,
    "system:events": `/${localeRouteParam}/system/:id/events`,
    "system:events-page2": `/${localeRouteParam}/system/:id/events-page2`,
    "system:elements": `/${localeRouteParam}/system/:id/elements`,
    "system:settings": `/${localeRouteParam}/system/:id/settings`,
    "system:shares": `/${localeRouteParam}/system/:id/share`,
    "system:share-settings": `/${localeRouteParam}/system/:id/share/:userId`,//`:systemId/share/`:userId`,
    "system:phonenumber": `/${localeRouteParam}/system/:id/phonenumber`,

    "system:Documentationhistory": `/${localeRouteParam}/system/:id/Documentation-history`,
    "system:displaydevice": `/${localeRouteParam}/system/:id/display-device`,
    "system:powerunitsDocumentation": `/${localeRouteParam}/system/:id/power-units-Documentation`,
    "system:otheralarmdevices": `/${localeRouteParam}/system/:id/other-alarm-devices`,
    "system:otherdevices": `/${localeRouteParam}/system/:id/other-devices`,
    "system:inputssystemDocumentation": `/${localeRouteParam}/system/:id/inputs-system-Documentation`,
    "system:submissiondocuments": `/${localeRouteParam}/system/:id/submission-documents`,
    "system:typedefinitionsubmissiondocuments": `/${localeRouteParam}/system/:id/type-definition-submission-documents`,

    "system:plan": `/${localeRouteParam}/system/:id/plan/:planId?`,
    "system:maintenancereport": `/${localeRouteParam}/system/:id/maintenancereport`,
    "system:eventmemory": `/${localeRouteParam}/system/:id/eventmemory`,
    "system:analoguevalues": `/${localeRouteParam}/system/:id/analoguevalues`,
    "system:queries": `/${localeRouteParam}/system/:id/queries`,
    "system:addsystem": `/${localeRouteParam}/system/:id/new-system`,

    "customers": `/${localeRouteParam}/customer`,
    "customer:customers": `/${localeRouteParam}/customer/:id/customers`,
    "customer": `/${localeRouteParam}/customer/:id`,
    "customer:systems": `/${localeRouteParam}/customer/:id/systems`,
    "customer:users": `/${localeRouteParam}/customer/:id/users`,
    "customer:events": `/${localeRouteParam}/customer/:id/events`,
    "customer:addcustomer": `/${localeRouteParam}/customer/:id/new-customer`,
    "customer:adduser": `/${localeRouteParam}/customer/:id/new-user`,
    "customer:settings": `/${localeRouteParam}/customer/:id/settings`,

    "users": `/${localeRouteParam}/user`,
    "user": `/${localeRouteParam}/user/:id`,

    "forgot-password": `/${localeRouteParam}/forgot-password`,
    // "reset-password": `/${localeRouteParam}/reset-password/:token`,
    // "login-redirect": `/${localeRouteParam}/:rest(?!reset-password|forgot-passwort)`,
    "auth-component": `/${localeRouteParam}?/:component?/:rest(.*)?`,
    "login": `/${localeRouteParam}?`
});

registerDefaults({
    locale: Locales.default
});