import { NumberGroup } from "@ews/zlt-events";

export type GroupItemChildType = {
    childGroupId: string,
    title: string;
};
export type GroupItemType = {
    groupId: number,
    title: string;
    childGroup?: GroupItemChildType[] | [];
};

export type GroupType = {
    title: string,
    svg: string,
    list: GroupItemType[] | [];
};

type RangeFilter = {
    zoneFrom?: string,
    zoneTo?: string;
};

export const FilterCategories = [
    NumberGroup.Zone,
    NumberGroup.ActuationDevice,
    NumberGroup.FloodingZone,
    NumberGroup.AlarmingDevice,
    NumberGroup.TransmissionDevice
] as const;

export type FilterGroups = {
    [NumberGroup.Zone]?: RangeFilter;
    [NumberGroup.ActuationDevice]?: RangeFilter;
    [NumberGroup.FloodingZone]?: RangeFilter;
    [NumberGroup.AlarmingDevice]?: RangeFilter;
    [NumberGroup.TransmissionDevice]?: RangeFilter;
};
