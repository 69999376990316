import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonList,
    IonNote,
    IonPage,
    IonRow
} from "@ionic/react";

import
{
    FC,
    FormEvent,
    PropsWithChildren,
    useState
} from "react";

import
{
    useAuth,
    useForgotPassword,
    useResetPassword
} from "./LoginProvider";

import { Link } from "react-router-dom";
import { useNamedRoutes } from "../NamedRoutes";
import { eye, eyeOff } from 'ionicons/icons';

const LoginPageWrapper: FC<PropsWithChildren & { title: string; }> = ({ children, title }) =>
{

    const { translate: t } = useL10n();

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow class="ion-justify-content-center">
                        <IonCol class="ion-align-self-center" size-md="8" size-lg="6" size-xl="4" size-sm="12">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>{t(title)}</IonCardTitle>
                                    <IonCardSubtitle></IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {children}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

const LoginPage: FC = () =>
{
    const { translate: t } = useL10n();
    const { login } = useAuth();
    const { generate } = useNamedRoutes();

    //const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState('');

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const doLogin = async (username: string, password: string, e?: FormEvent<HTMLFormElement>) =>
    {
        if (e) e.preventDefault();

        try {
            await login(username, password);
        } catch (e) {

            setLoginError(e as string);
        }
    };

    return (
        <LoginPageWrapper title="Anmeldung">
            <form onSubmit={(e) => doLogin(username, password, e)}>
            <IonList>
    <IonItem>
        <IonInput 
            name="username" 
            className="login-input" 
            type="text" 
            onIonInput={(e) => setUsername(e.target.value + '')} 
            labelPlacement="floating" 
            label={t('Username')} 
        />
    </IonItem>

    <IonItem>
        <IonInput
            name="password"
            className="login-input"
            type={showPassword ? "text" : "password"}
            onIonInput={(e) => setPassword(e.target.value + '')}
            labelPlacement="floating"
            label={t('Password')}
        />
        <IonButton fill="clear" onClick={() => setShowPassword(!showPassword)} slot="end">
            <IonIcon icon={showPassword ? eyeOff : eye} />
        </IonButton>
    </IonItem>

    <IonButton type="submit" className="ion-padding" expand="block">
        {t('Anmelden')}
    </IonButton>

    {loginError && <IonNote color="danger">{loginError}</IonNote>}
</IonList>

                <Link to={generate('forgot-password')}>{t('Passwort zurücksetzen')}</Link>
            </form>
        </LoginPageWrapper>
    );
};

const ForgotPasswordPage: FC = () =>
{
    const { translate: t } = useL10n();
    const forgotPassword = useForgotPassword();
    const { generate } = useNamedRoutes();

    const [error, setError] = useState('');
    const [done, setDone] = useState(false);

    const [username, setUsername] = useState("");

    const requestEmail = async (username: string, e?: FormEvent<HTMLFormElement>) =>
    {
        if (e) e.preventDefault();
        try {
            await forgotPassword(username);
            setDone(true);
        } catch (e) {

            setError(e as string);
        }
    };

    return (
        <LoginPageWrapper title="Passwort vergessen">
            {done ?
                <div>
                    <p>Ein Email wurde an Sie geschickt. Bitte folgen Sie den Anweisungen zum Zurücksetzen Ihres Passwortes.</p>
                    <Link to={generate('login')}>{t('Zur Anmeldung')}</Link>
                </div>
                :
                <form onSubmit={(e) => requestEmail(username, e)}>
                    <IonList>
                        <IonItem>
                            <IonInput name="username" className="login-input" type="text" onIonInput={(e) => setUsername(e.target.value + '')} labelPlacement="floating" label={t('Username')} />
                        </IonItem>

                        <IonButton type="submit" className="ion-padding" expand="block" >{t('Zurücksetzen anfordern')}</IonButton>

                        {error && <IonNote color="danger">{error}</IonNote>}

                    </IonList>
                </form>
            }
        </LoginPageWrapper>
    );
};

const ResetPasswordPage: FC<{ token?: string; }> = ({ token }) =>
{
    const { translate: t } = useL10n();
    const resetPassword = useResetPassword();
    const [error, setError] = useState('');
    const { generate } = useNamedRoutes();
    //const { token } = params();
    const [password, setPassword] = useState("");
    const [done, setDone] = useState(false);

    const doResetPassword = async (password: string, e?: FormEvent<HTMLFormElement>) =>
    {
        if (e) e.preventDefault();

        try {
            await resetPassword(token!, password);
            setDone(true);
        } catch (e) {

            setError(e as string);
        }
    };

    return (
        <LoginPageWrapper title="Passwort zurücksetzen">
            {done ?
                <div>
                    <p>Ihr Passwort wurde zurück gesetzt. Bitte melden Sie sich <Link to={generate('login')}>{t('hier')}</Link> an.</p>
                </div>
                :
                <form onSubmit={(e) => doResetPassword(password, e)}>
                    <IonList>
                        <IonItem>
                            <IonInput name="username" className="login-input" type="password" onIonInput={(e) => setPassword(e.target.value + '')} labelPlacement="floating" label={t('Password')} />
                        </IonItem>

                        <IonButton type="submit" className="ion-padding" expand="block" >{t('Reset Password')}</IonButton>

                        {error && <IonNote color="danger">{error}</IonNote>}

                    </IonList>
                </form >
            }
        </LoginPageWrapper >
    );
};



export default LoginPage;

export
{
    ForgotPasswordPage,
    ResetPasswordPage
}

