import
{
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonRow
} from "@ionic/react";

import
{
    SwiperSlide,
    useSwiper
} from 'swiper/react';

import
{
    RTMDisplay,
    SystemRTM,
    SystemType,
    UserEventDisplay
} from "@ews/react-data";

import React, {
    useRef
} from "react";

import
{
    useIsMobile
} from "../../Responsive";

import
{
    EventItem
} from "../EventItem/EventItem";

import
{
    distributeRTMFields
} from "../helper.ts/distributeRTMFields";

import
{
    EventObject,
    RTMFieldOrder
} from "../types";

import
{
    NoDeviceNumber,
    NumberGroup,
    RTMCategory
} from "@ews/zlt-events";

import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    useMyEventDisplay
} from "../../Login/LoginProvider";

import SettingsSwiper from "../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import EventCardButton from "../EventCardButton/EventCardButton";
import eventStyle from './EventOverview.module.scss';
import
{
    useCurrentSystem,
    useSystem
} from "../../ReactData/system";
import FilterList from "../../FilterList/FilterList";
import { FilterCategories, FilterGroups } from "../../Group/types";
import SettingsTextInput from "../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { reload } from "ionicons/icons";
import { usePersistentState } from "../../Persistence";
import { useCurrentSystemId } from "../../Authorization";
import { zoneCompare } from "../../Group/GroupOverview/helper";

const EventOverview: React.FC<{
    eventTab?: RTMDisplay,
    rtm: SystemRTM[];
    isFilterOpen?: boolean,
    onIsOpen?: (status: boolean) => void;
}> = ({
    eventTab = RTMDisplay.eventsTab1,
    rtm,
    isFilterOpen,
    onIsOpen
}) =>
    {
        const systemId = useCurrentSystemId();
        const system = useSystem(systemId);

        const eventDisplay = useMyEventDisplay();
        const { translate: t } = useL10n();
        const mobileView = useIsMobile();
        const [rangeFilter, setRangeFilter] = usePersistentState<FilterGroups>('system:filter', {}, systemId);

        const slideToRef = useRef<number>(0);
        const SwiperRef = useRef<any>();

        const rtmFields = distributeRTMFields(rtm.filter(e =>
        {
            const { numberGroup } = e;
            const filter = rangeFilter[numberGroup as keyof FilterGroups];

            if (filter) {
                return zoneCompare(e.zone, parseInt(filter.zoneFrom || "1")) >= 0 &&
                    zoneCompare(e.zone, parseInt(filter.zoneTo || `${NoDeviceNumber}`)) <= 0;
            }

            return true;
        }));

        const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
        {
            const swiper = useSwiper();

            return <IonInput
                label=""
                ref={swipeRef}
                onClick={(e) =>
                {
                    swiper.slideToLoop(slideToRef.current);
                }}
                hidden />;
        };

        const swiperOption = {
            spaceBetween: 40,
            slidesPerView: 1,
            duration: '2s',
            animation: 'ease-in-out'
        };

        if (!("tabCategoryAlarm" in eventDisplay)) return <></>;

        rtmFields[RTMCategory.None] = null;

        if (!system.hasFloodingZone) {
            rtmFields[RTMCategory.ExtinguishingEvent] = null;
        }

        if (system.systemType === SystemType.BC216) {
            rtmFields[RTMCategory.Confirmation] = null;

        } else if (!mobileView) {
            for (const rtmField of RTMFieldOrder) {
                if (eventDisplay[`tabCategory${rtmField}` as keyof UserEventDisplay] !== eventTab) {
                    rtmFields[RTMCategory[rtmField]] = null;
                }
            }
        }

        const SwiperComponent = mobileView ? SettingsSwiper : IonGrid;
        const SliderComponent = mobileView ? SwiperSlide : ({ children }: { children: React.ReactNode; }) => <>{children}</>;
        const ColComponent = mobileView ? ({ children }: { children: React.ReactNode; }) => <>{children}</> : IonCol;
        const RowComponent = mobileView ? ({ children }: { children: React.ReactNode; }) => <>{children}</> : IonRow;
        let secondPage: EventObject[] | [] = [];

        //if (eventList.length > 6 && !mobileView) secondPage = eventList.splice(6, eventList.length - 1)

        return <>{/* <IonContent className={eventStyle["event-card-content-container"]} style={mobileView ? { height: '85%' } : {}}>{} */}
            {/*  {secondPage.length && !mobileView ? <SettingsSegment value={currentPage} onSelect={(current) => setCurrentPage(current)}>
            <IonSegmentButton value="page 1">{'Event 1'}</IonSegmentButton>
            <IonSegmentButton value="page 2">{'Event 2'}</IonSegmentButton>
        </SettingsSegment> : null} */}

            {mobileView && <FilterList
                onIsOpen={onIsOpen}
                isOpen={isFilterOpen}
                isDetailsHidden={false}

                details={<>
                    {FilterCategories.map((key) =>
                    {
                        rangeFilter[key as keyof FilterGroups] = rangeFilter[key as keyof FilterGroups] || {};

                        if (key === NumberGroup.FloodingZone && !system.hasFloodingZone) {
                            return <React.Fragment key={key} />;
                        }

                        return <IonCol key={key} sizeLg="3" sizeMd="1" sizeSm="1">
                            <IonItem>
                                <div style={{ width: "10em", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{t(`GROUP_RANGE_PICKER_${key}`)}</div>
                                <SettingsTextInput
                                    text=""
                                    textAlign="right"
                                    inputType="number"
                                    value={`${rangeFilter[key as unknown as keyof FilterGroups]?.zoneFrom || 1}`}
                                    onChange={(value) =>
                                    {
                                        rangeFilter[key as unknown as keyof FilterGroups]!.zoneFrom = value;
                                        setRangeFilter({ ...rangeFilter });
                                    }}
                                /> -
                                <SettingsTextInput
                                    text=""
                                    textAlign="right"
                                    inputType="number"
                                    value={`${rangeFilter[key as unknown as keyof FilterGroups]?.zoneTo || NoDeviceNumber}`}
                                    onChange={(value) =>
                                    {
                                        rangeFilter[key as unknown as keyof FilterGroups]!.zoneTo = value;
                                        setRangeFilter({ ...rangeFilter });
                                    }}
                                />
                                <IonButton
                                    onClick={() =>
                                    {
                                        rangeFilter[key as unknown as keyof FilterGroups] = {};
                                        setRangeFilter({ ...rangeFilter });
                                    }}
                                ><IonIcon icon={reload} /></IonButton>
                            </IonItem>
                        </IonCol>;
                    })}
                </>
                } />}

            <SwiperComponent
                className={eventStyle['event-swiper']}
                spaceBetween={swiperOption.spaceBetween}
                slidesPerView={1}
                loop
            >
                {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}
                {mobileView ? <SliderComponent key={'event-overview-slider-main'} >
                    <IonGrid className={mobileView ? eventStyle['event-overview-grid'] : ''} >
                        <IonRow >
                            {RTMFieldOrder.map((categoryType, index) => 
                            {
                                const category: RTMCategory = RTMCategory[categoryType];
                                const rtm = rtmFields[category];

                                if (!rtm) return <React.Fragment key={categoryType} />;

                                return <IonCol size="4" key={categoryType} className={eventStyle["event-card-button"]} >
                                    <EventCardButton

                                        count={rtm.length}
                                        swiperIndex={index + 1}
                                        title={t(`FC_${categoryType}`)}
                                        type={categoryType}
                                        onClick={(page) =>
                                        {
                                            slideToRef.current = page;
                                            SwiperRef.current.click();
                                        }} />
                                </IonCol>;
                            })}
                        </IonRow>
                    </IonGrid>
                </SliderComponent> : null}

                <RowComponent className={eventStyle["event-row"]} >
                    <>
                        {RTMFieldOrder.map((categoryType) => 
                        {
                            const category: RTMCategory = RTMCategory[categoryType];
                            const rtm = rtmFields[category];

                            if (!rtm) return <React.Fragment key={categoryType} />;

                            return <SliderComponent key={categoryType} className={eventStyle['event-swiper']}>
                                <ColComponent size="4">
                                    <EventItem rtm={rtm} title={t(`FC_${categoryType}`)} />

                                </ColComponent>
                            </SliderComponent>;
                        })}
                    </>
                </RowComponent>
            </SwiperComponent >
            {
                secondPage.length ? <RowComponent>
                    <></>
                    {/*  {secondPage.map((event: EventObject, index: number) =>
            {

                return <SliderComponent key={'event-overview-slider-' + index} className={eventStyle['event-swiper']}>

                    <ColComponent size="4">
                        <EventItem event={event} />
                    </ColComponent>
                </SliderComponent>;

            })} */}
                </RowComponent> : null
            }

            {/* </IonContent> */}
        </>;
    };
export default EventOverview;