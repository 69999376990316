import React, { useState } from "react";

const storagePrefix = "react:persistence";

type Bucket = {
    data: any,
    guard?: string;
};

const retrieve = (key: string): Bucket | null =>
{
    const cache = sessionStorage.getItem(`${storagePrefix}:${key}`);
    return cache === null ? null : JSON.parse(cache);
};

const store = (key: string, data: Bucket) =>
{
    sessionStorage.setItem(`${storagePrefix}:${key}`, JSON.stringify(data));
    return data;
};

export const usePersistentState = <T extends any>(
    persistenceKey: string,
    defaultValue?: T,
    resetGuard?: string): [T, React.Dispatch<React.SetStateAction<T>>] =>
{
    let bucket = retrieve(persistenceKey);

    if (bucket === null || bucket.guard !== resetGuard) {
        bucket = store(persistenceKey, {
            data: defaultValue,
            guard: resetGuard
        });
    }

    const [data, setData] = useState<T>(bucket.data);
    const persistData: React.Dispatch<React.SetStateAction<T>> = (update: any) =>
    {
        if (typeof update === 'function') {
            update = update(data);
        }

        store(persistenceKey, {
            data: update,
            guard: resetGuard
        });

        setData(update);
    };

    return [data, persistData];
};