import { CSSProperties } from "react";
import { SystemElement } from "../../../ReactData/system";
import { Direction } from "leaflet";



export type Alignment = "right" | "left" | "top" | "bottom";

export type ShowToolTipLabelBackgroundClasses = "show" | "hide";

export const alignments: Alignment[] = ["right", "left", "top", "bottom"] as const;
export const labelPosition: Alignment[] = ["right", "left", "top", "bottom"] as const;
export const fontWeights: CSSProperties["fontWeight"][] = ["bold", "bolder", "normal", "lighter"] as const;
export const labelBackground: ShowToolTipLabelBackgroundClasses[] = ["show", "hide"];

export const fontFamilies: { label: string, class: string; }[] = [
    { label: "Cursive", class: "cursive" },
    { label: "Times New Roman", class: "times-new-roman" },
    { label: "Franklin Gothic Medium", class: "franklin-gothic-medium" },
    { label: "Verdana", class: "verdana" },
    { label: "Segoe UI", class: "segoe-ui" },
    { label: "Courier New", class: "courier" }
] as const;


/* export type FontStyle = {
    fontSize?: number;
    fontWeight?: CSSProperties["fontWeight"];
    fontFamily?: CSSProperties["fontFamily"];
    labelPosition?: Alignment;
    label?: string;
    iconScale?: number;
};
 */
/* export type Element = c &
{ systemPlanStyle?: FontStyle; }; */

type Events = {
    onAlignment?: (elements: SystemElement[]) => void;
    onChange?: (element: SystemElement, modified: { [key: string]: any; }) => void;
    onSelect?: (element: SystemElement) => void;
    onDelete?: (element: SystemElement) => void;
    onChangeTooltipDirection?: ({ direction, tooltipId }: { direction: Direction, tooltipId: number; }) => void;
};

export type ElementProps = {
    selectedElements: SystemElement[],
    element: SystemElement;
    planHeight?: number;
    planWidth?: number;
    zoom?: number;
    selected?: boolean;
} & Events;