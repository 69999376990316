import { UserSystemDisplay } from "@ews/react-data";
import { useL10n } from "@ews/react-localization-context";
import { DisplayCategory, NoDeviceNumber } from "@ews/zlt-events";
import { DisplayCategoryTranslation } from "@ews/zlt-events/dist/types";
import { IonButton, IonItem, IonList } from "@ionic/react";
import { useModifyUserSystemDisplay, useUserSystemDisplay } from "../../../ReactData/user";
import { useIsMobile } from "../../../Responsive";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import SettingsTitle from "../../../Settings/SettingsTitle/SettingsTitle";
import { SystemViewSettingsProps } from "./types";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";

const SystemViewSettings: React.FC<SystemViewSettingsProps> = ({ id }) =>
{
    const systemDisplay = useUserSystemDisplay(id!);
    const modifyUserSystemDisplay = useModifyUserSystemDisplay();
    const { translate: t } = useL10n();
    const isMobile = useIsMobile();

    const modifySystemDisplay = async (data: UserSystemDisplay) =>
    {
        await modifyUserSystemDisplay(id!, data);
    };

    if (!("displayCategory1" in systemDisplay)) return <></>;

    const filters = Array(5).fill(1).map((v, n) => n + 1);
    const displayCategories = Object.keys(DisplayCategory).filter(v => !isNaN(+v));
    const Wrapper = isMobile ? IonList : IonItem;


    return <>
        <SettingsCol xl="6">

            <SettingsTitle text={"Only show systems in case of"} endText={':'} />
            {filters.map(index =>
            {
                const category = `displayCategory${index}` as keyof UserSystemDisplay;
                const zoneFrom = `firstZone${index}` as keyof UserSystemDisplay;
                const zoneTo = `lastZone${index}` as keyof UserSystemDisplay;

                return <Wrapper lines="full">
                    <SettingsSelect

                        text={isMobile ? t('Ereignis') : ''}
                        value={`${systemDisplay[category]}`}
                        onSelect={(value) =>
                        {
                            modifySystemDisplay({ [category]: parseInt(value) } as unknown as UserSystemDisplay);
                        }}
                    >
                        {displayCategories.map(v =>
                        {
                            const key: keyof typeof DisplayCategoryTranslation = DisplayCategory[v as keyof typeof DisplayCategory] as unknown as keyof typeof DisplayCategoryTranslation;
                            return <SettingsSelectOption
                                value={v}
                                text={DisplayCategoryTranslation[key]}
                            ></SettingsSelectOption>;
                        })}

                    </SettingsSelect>
                    {!isMobile && <div style={{ whiteSpace: "nowrap" }}
                        hidden={systemDisplay[category] === DisplayCategory.None}
                    >{t("SYSTEM_VIEW_LABEL_AREA")}</div>}
                    <SettingsTextInput
                        inputType="number"
                        text={isMobile ? t('SYSTEM_VIEW_LABEL_AREA') : ''}
                        textAlign="right"
                        disabled={systemDisplay[category] === DisplayCategory.None}
                        value={`${systemDisplay[zoneFrom]}`}
                        onChange={(value) =>
                        {
                            modifySystemDisplay({ [zoneFrom]: parseInt(value) } as unknown as UserSystemDisplay);
                        }}
                    />{!isMobile && " - "}
                    <SettingsTextInput
                        inputType="number"
                        text={isMobile ? t('to') : ''}
                        textAlign="right"
                        disabled={systemDisplay[category] === DisplayCategory.None}
                        value={`${systemDisplay[zoneTo]}`}
                        onChange={(value) =>
                        {
                            modifySystemDisplay({ [zoneTo]: parseInt(value) } as unknown as UserSystemDisplay);
                        }}
                    />
                    <IonButton
                        disabled={systemDisplay[category] === DisplayCategory.None}
                        onClick={() =>
                        {
                            modifySystemDisplay({ [zoneFrom]: 1, [zoneTo]: NoDeviceNumber } as unknown as UserSystemDisplay);
                        }}
                    >{t('SYSTEM_VIEW_LABEL_RESET')}</IonButton>
                </Wrapper>;
            })}

            <SettingsCheckbox
                checked={systemDisplay.filterOnServer}
                onChange={(filterOnServer) =>
                {
                    modifySystemDisplay({ filterOnServer } as UserSystemDisplay);
                }}
                text={t("SYSTEM_VIEW_LABEL_FILTER_ON_SERVER")}
            />
        </SettingsCol>


    </>;
};

export default SystemViewSettings;