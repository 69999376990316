export function hasKey<
    T extends Record<string, any>,
    K extends keyof T
>
    (obj: T, key: K, checkIsUndefined: boolean = false)
    : obj is Exclude<T, keyof T> & Required<Pick<T, K>>
{
    const _hasKey = key in obj;
    return checkIsUndefined ? _hasKey && obj[key] !== undefined : _hasKey;
}
