import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem } from "@ionic/react";
import { SwiperSlide, useSwiper } from 'swiper/react';

import
{
    NoDeviceNumber,
    NumberGroup
} from '@ews/zlt-events';

import { SystemGroup } from "@ews/react-data";
import { reload } from "ionicons/icons";
import React, { FC, ReactNode, useRef, useState } from "react";
import { useCurrentSystemId } from "../../Authorization";
import FilterList from "../../FilterList/FilterList";
import { usePersistentState } from "../../Persistence";
import { useSystem } from "../../ReactData/system";
import { useIsMobile } from "../../Responsive";
import SettingsSwiper from "../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import SettingsTextInput from "../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import GroupItem from "../GroupItem/GroupItem";
import groupStyle from "./GroupOverview.module.scss";
import { firstLetterUpperCase, zoneCompare } from "./helper";
import { FilterCategories, FilterGroups } from "../types";

type List = {
    [NumberGroup.Zone]: { list: SystemGroup[], label: string; };
    [NumberGroup.ActuationDevice]: { list: SystemGroup[], label: string; };
    [NumberGroup.FloodingZone]: { list: SystemGroup[], label: string; };
    [NumberGroup.AlarmingDevice]: { list: SystemGroup[], label: string; };
    [NumberGroup.TransmissionDevice]: { list: SystemGroup[], label: string; };
};

const SliderComponentDesktop: FC<{
    children: React.ReactNode,
    className?: string;
}> = ({ children, className }) => <section className={`${groupStyle["slider-section-componente"]} ${className}`} > {children}</section >;

const Container: FC<{ className?: string, children: ReactNode, style?: Record<string, string>; }> = ({ children, style, className }) =>
{
    const mobileView = useIsMobile();
    return mobileView ? <>{children}</> : <div className={className} style={style}>{children}</div>;
};

const GroupOverview: React.FC<{
    groups: SystemGroup[],
    isFilterOpen?: boolean,
    onIsOpen?: (status: boolean) => void;
}> = ({
    groups,
    isFilterOpen,
    onIsOpen
}) =>
    {

        const systemId = useCurrentSystemId();
        const system = useSystem(systemId);

        const slideToRef = useRef<number>(0);
        const SwiperRef = useRef<any>();

        // const [rangeFilter, setRangeFilter] = useState<FilterGroups>(filterState);
        const [rangeFilter, setRangeFilter] = usePersistentState<FilterGroups>('system:filter', {}, systemId);

        const groupList: List = {
            [NumberGroup.Zone]: { list: [], label: "[SID_MNU_TAB_GROUPS]" },
            [NumberGroup.ActuationDevice]: { list: [], label: "[SID_MNU_TAB_CONTROLS]" },
            [NumberGroup.FloodingZone]: { list: [], label: "[SID_MNU_TAB_EXT_SEC]" },
            [NumberGroup.AlarmingDevice]: { list: [], label: "[SID_MNU_TAB_AL_DEVICES]" },
            [NumberGroup.TransmissionDevice]: { list: [], label: "[SID_MNU_TAB_TR_DEVICES]" },
        };

        const mobileView = useIsMobile();
        const { translate: t } = useL10n();

        const buttonTitelList = ["[SID_MNU_TAB_GROUPS]", "[SID_MNU_TAB_CONTROLS]", "[SID_MNU_TAB_EXT_SEC]", "[SID_MNU_TAB_AL_DEVICES]", "[SID_MNU_TAB_TR_DEVICES]"];
        const [currentPage, setCurrentPage] = useState(buttonTitelList[0]);

        const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
        {
            const swiper = useSwiper();
            return <IonInput
                label=""
                ref={swipeRef}
                onClick={(e) =>
                {

                    swiper.slideTo(slideToRef.current);
                }}
                hidden />;
        };

        groups.forEach((group) =>
        {
            const type = group.numberGroup as keyof List;
            const filter = rangeFilter[type];

            if (filter) {
                if (zoneCompare(group.id!, parseInt(filter.zoneFrom || "1")) >= 0 &&
                    zoneCompare(group.id!, parseInt(filter.zoneTo || `${NoDeviceNumber}`)) <= 0) {
                    groupList[type].list.push(group);
                }
            } else {
                groupList[type].list.push(group);
            }
        });

        const SwiperComponent = mobileView ? SettingsSwiper : IonGrid;
        const SliderComponent = mobileView ? SwiperSlide : SliderComponentDesktop;

        return (<>
            <div className={`${groupStyle["group-card-content-container"]} ${mobileView ? '' : 'ion-padding'}`} >

                {mobileView && <FilterList
                    onIsOpen={onIsOpen}
                    isOpen={isFilterOpen}
                    isDetailsHidden={false}

                    details={<>
                        {FilterCategories.map((key) =>
                        {
                            rangeFilter[key as keyof FilterGroups] = rangeFilter[key as keyof FilterGroups] || {};

                            if (key === NumberGroup.FloodingZone && !system.hasFloodingZone) {
                                return <React.Fragment key={key} />;
                            }

                            return <IonCol key={key} sizeLg="3" sizeMd="1" sizeSm="1">
                                <IonItem>
                                    <div style={{ width: "10em", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{t(`GROUP_RANGE_PICKER_${key}`)}</div>
                                    <SettingsTextInput
                                        text=""
                                        textAlign="right"
                                        inputType="number"
                                        value={`${rangeFilter[key as keyof FilterGroups]?.zoneFrom || 1}`}
                                        onChange={(value) =>
                                        {
                                            rangeFilter[key as keyof FilterGroups]!.zoneFrom = value;
                                            setRangeFilter({ ...rangeFilter });
                                        }}
                                    /> -
                                    <SettingsTextInput
                                        text=""
                                        textAlign="right"
                                        inputType="number"
                                        value={`${rangeFilter[key as keyof FilterGroups]?.zoneTo || NoDeviceNumber}`}
                                        onChange={(value) =>
                                        {
                                            rangeFilter[key as keyof FilterGroups]!.zoneTo = value;
                                            setRangeFilter({ ...rangeFilter });
                                        }}
                                    />
                                    <IonButton
                                        onClick={() =>
                                        {
                                            rangeFilter[key as keyof FilterGroups] = {};
                                            setRangeFilter({ ...rangeFilter });
                                        }}
                                    ><IonIcon icon={reload} /></IonButton>
                                </IonItem>
                            </IonCol>;
                        })}
                    </>
                    } />}

                <SwiperComponent loop
                    className={groupStyle["group-swiper"]}
                >

                    {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}
                    {mobileView ? <SliderComponent key={'group-overview-slider-main'} className={mobileView ? groupStyle["slide-height"] : ""}>
                        <div className={groupStyle.buttonContainer}>
                            {buttonTitelList.map((title, index) =>
                            {
                                if (title === groupList[NumberGroup.FloodingZone].label) {
                                    if (!system.hasFloodingZone) return <></>;
                                }

                                return <div key={index} className={groupStyle["button-wrapper"]} data-x={title}>
                                    <IonButton
                                        expand="full"
                                        color='primary'
                                        className={groupStyle["gp-btn"]}
                                        onClick={() =>
                                        {
                                            slideToRef.current = index + 1;
                                            SwiperRef.current.click();
                                        }}
                                    >
                                        <div className={groupStyle["pull-left"]}>{t(firstLetterUpperCase(title))}</div>

                                    </IonButton>
                                </div>;
                            })}
                        </div>
                    </SliderComponent> : null}

                    <Container className={groupStyle['container']}>

                        <Container className={groupStyle['group-container']}>
                            {
                                mobileView ? <>
                                    {
                                        Object.entries(groupList).map(([key, { list, label }]) =>
                                        {
                                            if (parseInt(key) === NumberGroup.FloodingZone && !system.hasFloodingZone) {
                                                return <React.Fragment key={key} />;
                                            }

                                            return <SliderComponent className={groupStyle['group-swiper']}>
                                                <GroupItem groups={list} title={label} />
                                            </SliderComponent>;
                                        })

                                    }


                                </> : <>

                                    {
                                        Object.entries(groupList).map(([key, { list, label }]) =>
                                        {
                                            if (parseInt(key) === NumberGroup.FloodingZone && !system.hasFloodingZone) {
                                                return <React.Fragment key={key} />;
                                            }

                                            return <GroupItem className={currentPage === label ? "" : groupStyle.none} groups={list} title={label} />;
                                        })

                                    }

                                </>
                            }
                        </Container>
                        {!mobileView ? <div className={groupStyle.buttonContainer}>
                            {Object.entries(groupList).map(([key, { list, label }], index) =>
                            {
                                if (parseInt(key) === NumberGroup.FloodingZone && !system.hasFloodingZone) {
                                    return <React.Fragment key={key} />;
                                }

                                return <div key={index}>
                                    <IonButton
                                        expand="full"
                                        color={currentPage === label ? 'secondary' : 'primary'}
                                        onClick={() => { setCurrentPage(label); }}
                                    >{firstLetterUpperCase(t(label))}</IonButton>
                                </div>;
                            })}
                        </div> : null}
                    </Container>
                </SwiperComponent>
            </div >
        </>
        );
    };
export default GroupOverview;
